<template>
  <div>
    <DxTabPanel>
      <DxItem title="Indikator Penilaian" template="score-indicator" />
      <template #score-indicator>
        <tableRiskScoreIndicator
          :permission-props="permissionProps"
          :riskControlItemProps="riskControlItemProps"
        ></tableRiskScoreIndicator>
      </template>

      <DxItem
        title="Risk Control"
        template="risk-control"
        v-if="riskControlItemProps.type == 'Pengendalian Risiko'"
      />
      <DxItem title="Risk Severity" template="risk-severity" v-else />

      <template #risk-control>
        <tableRiskControl
          :permission-props="permissionProps"
          :riskControlItemProps="riskControlItemProps"
        ></tableRiskControl>
      </template>

      <template #risk-severity>
        <tableRiskSeverity
          :permission-props="permissionProps"
          :riskControlItemProps="riskControlItemProps"
        ></tableRiskSeverity>
      </template>
    </DxTabPanel>
  </div>
</template>

<script>
import DxButton from "devextreme-vue/button";
import { DxTabPanel, DxItem } from "devextreme-vue/tab-panel";
import DxDateBox from "devextreme-vue/date-box";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { mapGetters, mapActions } from "vuex";
import notify from "devextreme/ui/notify";

import tableRiskScoreIndicator from "@/components/riskManagement/tableRiskScoreIndicator.vue";
import tableRiskControl from "@/components/riskManagement/tableRiskControl.vue";
import tableRiskSeverity from "@/components/riskManagement/tableRiskSeverity.vue";

export default {
  components: {
    DxTabPanel,
    DxItem,
    DxDateBox,
    DxButton,
    tableRiskScoreIndicator,
    // tableDashboardAccess,
    DxLoadIndicator,
    DxValidator,
    DxRequiredRule,
    tableRiskControl,
    tableRiskSeverity,
  },
  data() {
    return {
      loading: false,
      permissions: {
        canCreate: null,
        canUpdate: null,
        canDelete: null,
        canVerified: null,
        unfilteredData: null,
      },
    };
  },
  computed: {
    ...mapGetters("authentication", ["getUserDetail"]),
    checkForm() {
      if (this.permissions.canCreate && (this.auditee || this.history)) {
        return false;
      }
      return true;
    },
  },
  mounted() {},
  props: {
    riskControlItemProps: {
      type: Object,
    },
    permissionProps: {
      type: Object,
    },
  },
  methods: {},
};
</script>
<style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
.table-left {
  width: 40%;
  margin: left;
}
</style>