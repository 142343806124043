<template>
  <div>
    <div class="master-detail-caption">Dokumen Regulasi :</div>
    <dx-data-grid
      class="dx-card wide-card"
      :data-source="dataSource"
      :show-borders="false"
      :column-auto-width="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :remote-operations="true"
      :word-wrap-enabled="true"
      :ref="gridRef"
      @toolbar-preparing="onToolbarPreparing($event)"
    >
      <dx-paging :page-size="10" />
      <dx-pager
        :show-page-size-selector="true"
        :show-info="true"
        :allowed-page-sizes="pageSizes"
        :showNavigationButtons="true"
        infoText="Hal {0} dari {1} ({2} items)"
        :visible="true"
      />
      <dx-column type="buttons" :width="110" data-field="Action">
        <DxButton
          hint="edit"
          icon="fa fa-pen-to-square"
          @click="edit"
          v-if="permissionProps.canUpdate && manageRegulationProps"
        />
        <DxButton
          hint="hapus"
          icon="fa fa-trash"
          @click="popupDestroy"
          v-if="permissionProps.canDelete && manageRegulationProps"
        />
      </dx-column>
      <dx-column data-field="regulation_file" caption="Nama File" />
      <dx-column
        data-field="link_regulation_file"
        caption="Download File"
        cell-template="grid-cell"
      />
      <template #grid-cell="{ data }">
        <div>
          <a
            :href="data.value"
            target="_blank"
            class="fa-solid fa-download link-icon"
          ></a>
        </div>
      </template>
    </dx-data-grid>

    <!-- popup delete -->
    <DxPopup
      :width="300"
      height="auto"
      :show-title="true"
      title="Konfirmasi"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      v-model:visible="popupDelete"
    >
      <template #content="{}">
        <div class="popup-property-details">
          <b>Apakah yakin hapus data ?</b>
          <div class="justify-center">
            <DxButtonx
              class="customBtn"
              text="ya"
              icon="check"
              type="success"
              @click="confirmDestroy()"
            />
            <DxButtonx
              class="customBtn"
              text="batal"
              icon="close"
              type="danger"
              @click="popupDelete = false"
            />
          </div>
        </div>
      </template>
    </DxPopup>
  </div>
</template>
<script>
import DxDataGrid, {
  DxColumn,
  DxFilterRow,
  DxLookup,
  DxPager,
  DxPaging,
  DxEditing,
  DxForm,
  DxSearchPanel,
  DxButton,
  DxMasterDetail,
} from "devextreme-vue/data-grid";
import CustomStore from "devextreme/data/custom_store";
import axios from "axios";
import router from "@/router";
import { mapGetters, mapActions } from "vuex";
import DxPopup from "devextreme-vue/popup";
import DxButtonx from "devextreme-vue/button";
import notify from "devextreme/ui/notify";

const isNotEmpty = (value) =>
  value !== undefined && value !== null && value !== "";
export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxLookup,
    DxPager,
    DxPaging,
    DxEditing,
    DxPopup,
    DxForm,
    DxSearchPanel,
    DxButton,
    DxMasterDetail,
    DxButtonx,
  },
  data() {
    const vthis = this;
    return {
      gridRef: "detail-table",
      dataSource: [],
      pageSizes: [5, 10, 15],
      unitBisnis: "",
      department: "",
      btnAdd: null,
      popupDelete: false,
      deleteRegDoc: {},
    };
  },
  mounted() {
    this.dataSource = this.regulationListProps.regulation_document;
  },
  props: {
    regulationListProps: {
      type: Object,
      default: null,
    },
    permissionProps: {
      type: Object,
      default: null,
    },
    manageRegulationProps: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dataGrid: function () {
      return this.$refs[this.gridRef].instance;
    },
    ...mapGetters("regulationDoc", ["getRegulationDoc"]),
  },
  methods: {
    confirmDestroy() {
      const vThis = this;
      this.$store
        .dispatch(
          "regulationDoc/destroyRegulationDocuments",
          this.deleteRegulation
        )
        .then((res) => {
          notify("delete success", "success", 2000);
          vThis.dataSource = vThis.dataSource.filter(
            (item) => item.id !== vThis.deleteRegulation.id
          );
          this.dataGrid.refresh();
        })
        .catch((e) => {
          notify(e.message, "error", 2000);
        });
      this.popupDelete = false;
    },
    popupDestroy(params) {
      console.log(params.row.data);

      this.popupDelete = true;

      this.deleteRegulation = params.row.data;
    },
    edit(params) {
      if (this.regulationListProps.status == "done") {
        notify("Regulasi sudah done", "warning", 2000);
        return;
      }
      this.$store.dispatch("regulationDoc/setRegulationDoc", params.row.data);
      router.push(`/regulation-document/form/edit/${params.row.data.id}`);
    },
    onToolbarPreparing(e) {
      const vThis = this;
      if (this.permissionProps.canCreate && this.manageRegulationProps) {
        e.toolbarOptions.items.unshift({
          location: "before",
          widget: "dxButton",
          options: {
            icon: "add",
            text: "Tambah",
            stylingMode: "outlined",
            type: "default",
            onInitialized: function (e) {
              this.btnAdd = e.component;
            },
            onClick: function () {
              router.push(
                `/regulation-document/form/add/${vThis.regulationListProps.id}`
              );
            },
          },
        });
      }
    },
  },
};
</script>
<style scoped>
.justify-center {
  margin: 2%;
}
.table-left {
  width: 60%;
  margin: left;
}
.link-icon {
  text-decoration: none;
  font-size: 18px;
}

.popup-property-details {
  overflow: hidden;
  position: relative;
}
.justify-center {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.customBtn {
  margin: 1%;
}
.master-detail-caption {
  padding: 0 0 5px 10px;
  font-size: 14px;
  font-weight: bold;
}
</style>