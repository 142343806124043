<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <form action="/addRiskImpPlan" @submit.prevent="handleSubmit">
        <div v-for="(form, index) in improv" :key="index">
          <DxSelectBox
            class="custom-form"
            :data-source="controlOptions"
            label="Pilih Pengendali Risiko"
            value-expr="id"
            display-expr="description"
            v-model="form.control_id"
            :is-required="true"
          >
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxSelectBox>

          <DxTextArea
            class="custom-form"
            label="Rencana Perbaikan"
            :height="90"
            :input-attr="{ 'aria-label': 'Notes' }"
            v-model:value="form.description"
          >
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxTextArea>

          <div class="input-group">
            <DxTextBox
              class="group"
              label="By Who"
              v-model:value="form.improve_by"
              :is-required="true"
              ><DxValidator>
                <DxRequiredRule message="Required" />
              </DxValidator>
            </DxTextBox>

            <DxDateBox
              class="group"
              label="By When"
              :input-attr="{ 'aria-label': 'Date' }"
              type="date"
              v-model:value="form.improve_at"
              display-format="dd / MM / yyyy"
            >
              <DxValidator>
                <DxRequiredRule message="Required" />
              </DxValidator>
            </DxDateBox>
          </div>
          <DxButton
            v-if="improv.length > 1"
            class="customBtn"
            type="danger"
            icon="fa fa-minus"
            @click="removeForm(index)"
          />
        </div>

        <div v-if="!loading">
          <DxButton
            v-if="paramsRoute.condition == 'add' && improv.length < 10"
            class="customBtn"
            type="default"
            icon="fa fa-plus"
            @click="addForm"
          />
          <DxButton
            text="Simpan Data"
            type="success"
            icon="save"
            :use-submit-behavior="true"
          />
        </div>
        <DxLoadIndicator v-else />
      </form>
    </div>
  </div>
</template>
            
      <script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxEmailRule,
} from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { mapGetters, mapActions } from "vuex";
import DxDateBox from "devextreme-vue/date-box";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";

import notify from "devextreme/ui/notify";
import axios from "axios";
import router from "@/router";
import FilterTableUnitBisnis from "@/components/partials/FilterTableUnitBisnis.vue";

export default {
  data() {
    return {
      loading: false,
      improv: [
        {
          control_id: "",
          description: "",
          improve_by: "",
          improve_at: "",
        },
      ],
      controlOptions: [],
      paramsRoute: this.$route.params,
    };
  },
  mounted() {
    if (
      this.paramsRoute.condition == "edit" &&
      this.paramsRoute.id.trim() !== ""
    ) {
      if (this.getRiskImpPlan) {
        this.improv[0] = this.getRiskImpPlan;
      } else {
        this.loadRiskImpPlan(this.paramsRoute.id);
      }
    }
    this.getControl();
  },
  emits: ["get-risk-improv-plan"],
  computed: {
    ...mapGetters("authentication", ["getUserDetail"]),
    ...mapGetters("riskImprovementPlan", [
      "getRiskImpPlan",
      "getRiskImpPlanStatus",
    ]),
  },
  methods: {
    async loadRiskImpPlan(params) {
      try {
        const riskImp = await this.$store.dispatch(
          "riskImprovementPlan/getRiskImpPlan",
          `/${params}`
        );

        this.improv[0] = riskImp.data;
      } catch (error) {
        notify(error, "error", 2000);
      }
    },
    async getControl() {
      const text = `/control-item?limit=99999`;
      const dataControl = await this.$store.dispatch(
        "riskControl/getRiskControl",
        text
      );
      this.controlOptions = dataControl.data;
    },
    addForm() {
      this.improv.push({
        control_id: "",
        description: "",
        improve_by: "",
        improve_at: "",
      });
    },
    removeForm(index) {
      this.improv.splice(index, 1);
    },
    async getRiskControl() {
      const text = `/control-item?limit=99999`;
      const dataControl = await this.$store.dispatch(
        "riskControl/getRiskControl",
        text
      );
      this.itemOptions = dataControl.data;
    },
    handleSubmit(e) {
      this.loading = true;
      this.$emit("get-risk-improv-plan", this.improv);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxEmailRule,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    DxDateBox,
    FilterTableUnitBisnis,
    DxLoadIndicator,
  },
};
</script>
      
      <style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
.customBtn {
  margin: 1%;
}
.group {
  display: inline-block;
}
.input-group {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(150px, 1fr)
  ); /* Auto-fit columns with a minimum width */
  gap: 10px; /* Optional: space between input elements */
  margin-bottom: 25px;
  margin-top: 10px;
}

.input-group .input-field {
  width: 100%;
}
</style>