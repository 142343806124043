<template>
  <div class="content-block dx-card responsive-paddings">
    <form action="/addRegulationList" @submit.prevent="handleSubmit">
      <DxCheckBox
        text="Bab : All"
        :value="all"
        class="custom-form"
        @valueChanged="checkbox"
      />
      <div class="input-group" v-if="!all">
        <DxTextBox
          class="group"
          label="Bab"
          v-model:value="regulationDetail.chapter"
          :is-required="true"
          ><DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxTextBox>
        <DxTextBox
          class="group"
          label="Pasal"
          v-model:value="regulationDetail.article"
          :is-required="true"
          ><DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxTextBox>
        <DxTextBox
          class="group"
          label="Ayat"
          v-model:value="regulationDetail.verse"
          :is-required="true"
          ><DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxTextBox>
      </div>
      <DxNumberBox
        class="custom-form"
        format="#"
        label="Jumlah bukti yang harus dipenuhi"
        :is-required="true"
        v-model:value="regulationDetail.total_impl"
        ><DxValidator>
          <DxRequiredRule message="Required" />
        </DxValidator>
      </DxNumberBox>
      <DxTextArea
        class="custom-form"
        label="Inti Sari"
        :height="90"
        :input-attr="{ 'aria-label': 'Notes' }"
        v-model:value="regulationDetail.summary"
      >
        <DxValidator>
          <DxRequiredRule message="Required" />
        </DxValidator>
      </DxTextArea>

      <DxButton
        v-if="!loading"
        text="Simpan"
        type="success"
        icon="save"
        :use-submit-behavior="true"
      />
      <DxLoadIndicator v-else />
    </form>
  </div>
</template>
        
<script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxEmailRule,
} from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxNumberBox from "devextreme-vue/number-box";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { mapGetters, mapActions } from "vuex";

import { DxCheckBox } from "devextreme-vue/check-box";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";

export default {
  data() {
    return {
      paramsRoute: this.$route.params,
      loading: false,
      regulationDetail: {},
      all: false,
    };
  },
  computed: {
    ...mapGetters("regulationDetail", [
      "getRegulationDetail",
      "getRegulationDetailStatus",
    ]),
    ...mapGetters("regulationList", ["getRegulationList"]),
    ...mapGetters("authentication", ["getUserDetail"]),
  },
  mounted() {
    if (
      this.paramsRoute.condition == "edit" &&
      this.paramsRoute.id.trim() !== ""
    ) {
      if (this.getRegulationDetail) {
        this.regulationDetail = this.getRegulationDetail;
      } else {
        this.loadRegulationDetail(this.paramsRoute.id);
      }
    }
  },
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["get-regulation-detail", "get-status"],
  methods: {
    async loadRegulationDetail(params) {
      try {
        const getRegDetail = await this.$store.dispatch(
          "regulationDetail/getRegulationDetails",
          `/${params}`
        );
        this.regulationDetail = getRegDetail.data;
      } catch (error) {
        notify(error, "error", 2000);
      }
    },
    checkbox(params) {
      this.regulationDetail.chapter = "";
      this.regulationDetail.article = "";
      this.regulationDetail.verse = "";
      if (params.value) {
        this.regulationDetail.chapter = "All";
        this.regulationDetail.article = "-";
        this.regulationDetail.verse = "-";
      }
      this.all = params.value;
    },
    async handleSubmit(e) {
      this.loading = true;
      this.$emit("get-regulation-detail", this.regulationDetail);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxEmailRule,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    DxCheckBox,
    DxNumberBox,
    DxLoadIndicator,
  },
};
</script>
        
        <style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
.group {
  display: inline-block;
}
.input-group {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(150px, 1fr)
  ); /* Auto-fit columns with a minimum width */
  gap: 10px; /* Optional: space between input elements */
  margin-bottom: 25px;
  margin-top: 10px;
}

.input-group .input-field {
  width: 100%;
}
</style>