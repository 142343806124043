<template>
  <div class="content-block dx-card responsive-paddings">
    <form action="/addRegulationList" @submit.prevent="handleSubmit">
      <DxTextBox
        class="custom-form"
        label="Nomor Regulasi"
        v-model:value="regulationList.regulation_number"
        :is-required="true"
        ><DxValidator>
          <DxRequiredRule message="Required" />
        </DxValidator>
      </DxTextBox>
      <DxTextArea
        class="custom-form"
        label="Topik Regulasi"
        :height="90"
        :input-attr="{ 'aria-label': 'Notes' }"
        v-model:value="regulationList.regulation_topic"
      >
        <DxValidator>
          <DxRequiredRule message="Required" />
        </DxValidator>
      </DxTextArea>
      <DxTextBox
        class="custom-form"
        label="Tipe Regulasi"
        v-model:value="regulationList.type_regulation"
        :is-required="true"
        ><DxValidator>
          <DxRequiredRule message="Required" />
        </DxValidator>
      </DxTextBox>

      <DxButton
        v-if="!loading"
        text="Simpan Data"
        type="success"
        icon="save"
        :use-submit-behavior="true"
      />
      <DxLoadIndicator v-else />
    </form>
  </div>
</template>
      
<script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxEmailRule,
} from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { mapGetters, mapActions } from "vuex";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";

import notify from "devextreme/ui/notify";
import axios from "axios";
import router from "@/router";

export default {
  data() {
    return {
      loading: false,
      regulationList: {},
      readOnlyDept: true,
      paramId: this.$route.params.id,
    };
  },
  mounted() {
    if (this.paramId && this.paramId.trim() !== "") {
      if (this.getRegulationList) {
        this.regulationList = this.getRegulationList;
      } else {
        this.loadRegulation(this.paramId);
      }
    }
  },
  emits: ["get-regulation-list"],
  computed: {
    ...mapGetters("regulationList", [
      "getRegulationList",
      "getRegulationListStatus",
    ]),
    ...mapGetters("authentication", ["getUserDetail"]),
  },
  methods: {
    async loadRegulation(params) {
      // reload
      try {
        const getRegulation = await this.$store.dispatch(
          "regulationList/getRegulationLists",
          `/${params}`
        );
        this.regulationList = getRegulation.data;
      } catch (error) {
        notify(error, "error", 2000);
      }
    },
    handleSubmit(e) {
      this.loading = true;
      this.regulationList.department_id = this.getUserDetail.department_id;

      this.$emit("get-regulation-list", this.regulationList);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxEmailRule,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    DxLoadIndicator,
  },
};
</script>

<style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
</style>