<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <form action="/addRiskIdentification" @submit.prevent="handleSubmit">
        <div v-for="(form, index) in identification" :key="index">
          <DxTextArea
            class="custom-form"
            label="Kegagalan / Risiko"
            :height="90"
            :input-attr="{ 'aria-label': 'Notes' }"
            v-model:value="form.risk_failure"
          >
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxTextArea>
          <div class="input-group">
            <DxTextArea
              class="group"
              label="Identifikasi / Analisa Faktor Internal "
              :height="90"
              :input-attr="{ 'aria-label': 'Notes' }"
              v-model:value="form.risk_couse_internal"
            >
              <DxValidator>
                <DxRequiredRule message="Required" />
              </DxValidator>
            </DxTextArea>

            <DxTextArea
              class="group"
              label="Identifikasi / Analisa Faktor Eksternal"
              :height="90"
              :input-attr="{ 'aria-label': 'Notes' }"
              v-model:value="form.risk_couse_external"
            >
              <DxValidator>
                <DxRequiredRule message="Required" />
              </DxValidator>
            </DxTextArea>
          </div>

          <div class="input-group">
            <DxTextArea
              class="group"
              label="Dampak Dari Risiko Ke Internal"
              :height="90"
              :input-attr="{ 'aria-label': 'Notes' }"
              v-model:value="form.risk_impact_internal"
            >
              <DxValidator>
                <DxRequiredRule message="Required" />
              </DxValidator>
            </DxTextArea>

            <DxTextArea
              class="group"
              label="Dampak Dari Risiko Ke Eksternal"
              :height="90"
              :input-attr="{ 'aria-label': 'Notes' }"
              v-model:value="form.risk_impact_external"
            >
              <DxValidator>
                <DxRequiredRule message="Required" />
              </DxValidator>
            </DxTextArea>
          </div>
          <DxButton
            v-if="identification.length > 1"
            class="customBtn"
            type="danger"
            icon="fa fa-minus"
            @click="removeForm(index)"
          />
        </div>

        <div v-if="!loading">
          <DxButton
            v-if="paramsRoute.condition == 'add' && identification.length < 10"
            class="customBtn"
            type="default"
            icon="fa fa-plus"
            @click="addForm"
          />

          <DxButton
            text="Simpan Data"
            type="success"
            icon="save"
            :use-submit-behavior="true"
          />
        </div>
        <DxLoadIndicator v-else />
      </form>
    </div>
  </div>
</template>
              
        <script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxEmailRule,
} from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { mapGetters, mapActions } from "vuex";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";

export default {
  data() {
    return {
      loading: false,
      identification: [
        {
          risk_failure: "",
          risk_couse_internal: "",
          risk_couse_external: "",
          risk_impact_internal: "",
          risk_impact_external: "",
        },
      ],
      paramsRoute: this.$route.params,
    };
  },
  mounted() {
    if (
      this.paramsRoute.condition == "edit" &&
      this.paramsRoute.id.trim() !== ""
    ) {
      if (this.getRiskIdentification) {
        this.identification[0] = this.getRiskIdentification;
      } else {
        this.loadImprovementReport(this.paramsRoute.id);
      }
    }
  },
  emits: ["get-identification"],
  computed: {
    ...mapGetters("risk", ["getRisk", "getRiskStatus"]),
    ...mapGetters("riskIdentification", [
      "getRiskIdentification",
      "getRiskIdentificationStatus",
    ]),
  },
  methods: {
    async loadImprovementReport(params) {
      try {
        const report = await this.$store.dispatch(
          "riskIdentification/getRiskIdentification",
          `/${params}`
        );

        this.identification[0] = report.data;
      } catch (error) {
        notify(error, "error", 2000);
      }
    },
    addForm() {
      this.identification.push({
        risk_failure: "",
        risk_couse_internal: "",
        risk_couse_external: "",
        risk_impact_internal: "",
        risk_impact_external: "",
      });
    },
    removeForm(index) {
      this.identification.splice(index, 1);
    },
    handleSubmit(e) {
      this.loading = true;
      this.$emit("get-identification", this.identification);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxEmailRule,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    DxLoadIndicator,
    // FilterTableUnitBisnis,
  },
};
</script>
        
        <style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
.group {
  display: inline-block;
}
.input-group {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(150px, 1fr)
  ); /* Auto-fit columns with a minimum width */
  gap: 10px; /* Optional: space between input elements */
  margin-bottom: 25px;
  margin-top: 10px;
}

.input-group .input-field {
  width: 100%;
}
.customBtn {
  margin: 1%;
}
</style>