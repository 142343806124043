<template>
  <div>
    <div class="master-detail-caption">Regulasi Implementasi :</div>
    <dx-data-grid
      class="dx-card wide-card"
      :data-source="dataSource"
      :show-borders="false"
      :column-auto-width="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :remote-operations="true"
      :word-wrap-enabled="true"
      :ref="gridRef"
      @toolbar-preparing="onToolbarPreparing($event)"
    >
      <dx-paging :page-size="10" />
      <dx-pager
        :show-page-size-selector="true"
        :show-info="true"
        :allowed-page-sizes="pageSizes"
        :showNavigationButtons="true"
        infoText="Hal {0} dari {1} ({2} items)"
        :visible="true"
      />

      <dx-column type="buttons" :width="110" data-field="Action">
        <DxButton
          hint="verifikasi"
          icon="fa fa-check-to-slot"
          @click="popupVerified"
          v-if="permissionProps.canVerified && verifiedRegulationProps"
        />
        <DxButton
          hint="edit"
          icon="fa fa-pen-to-square"
          @click="edit"
          v-if="permissionProps.canUpdate && manageRegulationProps"
        />
        <DxButton
          hint="hapus"
          icon="fa fa-trash"
          @click="popupDestroy"
          v-if="permissionProps.canDelete && manageRegulationProps"
        />
      </dx-column>
      <dx-column data-field="requirement_type" caption="Tipe Kebutuhan" />
      <dx-column data-field="requirement_detail" caption="Detail" />
      <dx-column
        data-field="pod_file"
        caption="Bukti"
        cell-template="grid-cell"
      />
      <template #grid-cell="{ data }">
        <div>
          <a
            :href="data.value"
            target="_blank"
            class="fa-solid fa-download link-icon"
            v-if="data.value"
          ></a>
          <p v-else>-</p>
        </div>
      </template>
      <dx-column
        data-field="upload_date"
        caption="Tanggal Unggah"
        :customize-text="dateColumn"
      />
      <dx-column
        data-field="verification_date"
        caption="Tanggal Verifikasi"
        :customize-text="dateColumn"
      />
      <dx-column
        data-field="verified"
        caption="Verifikasi"
        cell-template="verified"
      />
      <template #verified="{ data }">
        <div
          :class="{
            approve: data.value == true,
            reject: data.value == false,
          }"
          v-if="data.value != null"
        >
          {{ data.value ? "Approve" : "Reject" }}
        </div>
        <div v-else>Waiting</div>
      </template>
    </dx-data-grid>

    <!-- popup verification -->
    <DxPopup
      :width="300"
      height="auto"
      :show-title="true"
      title="Verifikasi"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      v-model:visible="popupVisible"
      :show-close-button="true"
    >
      <template #content="{}">
        <div class="popup-property-details">
          <b>Pastikan bukti sudah dicek!</b>
          <div class="justify-center">
            <DxButtonx
              class="customBtn"
              text="Approve"
              icon="check"
              type="success"
              @click="confirmVerification(true)"
              v-if="verifImpl.verified == null"
            />
            <DxButtonx
              class="customBtn"
              text="Reject"
              icon="close"
              type="danger"
              @click="confirmVerification(false)"
              v-if="verifImpl.verified == null"
            />
          </div>
        </div>
      </template>
    </DxPopup>

    <!-- popup delete -->
    <DxPopup
      :width="300"
      height="auto"
      :show-title="true"
      title="Konfirmasi"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      v-model:visible="popupDelete"
    >
      <template #content="{}">
        <div class="popup-property-details">
          <b>Apakah yakin hapus data ?</b>
          <div class="justify-center">
            <DxButtonx
              class="customBtn"
              text="ya"
              icon="check"
              type="success"
              @click="confirmDestroy()"
            />
            <DxButtonx
              class="customBtn"
              text="batal"
              icon="close"
              type="danger"
              @click="popupDelete = false"
            />
          </div>
        </div>
      </template>
    </DxPopup>
  </div>
</template>
<script>
import DxDataGrid, {
  DxColumn,
  DxFilterRow,
  DxLookup,
  DxPager,
  DxPaging,
  DxEditing,
  DxForm,
  DxSearchPanel,
  DxButton,
  DxMasterDetail,
} from "devextreme-vue/data-grid";
import CustomStore from "devextreme/data/custom_store";
import axios from "axios";
import router from "@/router";
import DxPopup from "devextreme-vue/popup";
import DxButtonx from "devextreme-vue/button";
import notify from "devextreme/ui/notify";
import formRegulationImpl from "./formRegulationImpl.vue";
import { mapGetters, mapActions } from "vuex";

const isNotEmpty = (value) =>
  value !== undefined && value !== null && value !== "";
export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxLookup,
    DxPager,
    DxPaging,
    DxEditing,
    DxPopup,
    DxForm,
    DxSearchPanel,
    DxButton,
    DxMasterDetail,
    DxButtonx,
    formRegulationImpl,
  },
  data() {
    const vthis = this;
    const dataSource = {
      store: new CustomStore({
        key: "id",
        load: async function (loadOptions) {
          var sortedBy = "";
          var sort = "";
          let filterValue = "";
          if (loadOptions.sort) {
            sortedBy += loadOptions.sort[0].selector;
            if (loadOptions.sort[0].desc) {
              sort += "desc";
            } else {
              sort += "asc";
            }
          }

          if (loadOptions.filter) {
            filterValue = loadOptions.filter[0].filterValue;
          }

          var perPage = loadOptions.take;
          var offset = loadOptions.skip;
          var page = offset / perPage + 1;

          var datax = vthis.fetch({
            limit: perPage || 10,
            page: page || 1,
            sorted_by: sortedBy || "verified",
            sort: sort || "desc",
            regulation_detail: vthis.regulationDetailProps?.id,
          });

          return await datax;
        },
      }),
    };
    return {
      gridRef: "regulation-detail-table",
      // detail: this.regDetail,
      dataSource: dataSource,
      pageSizes: [5, 10, 15],
      unitBisnis: "",
      department: "",
      btnAdd: null,
      verifImpl: "",
      popupVisible: false,
      countImplVerif: "",
      popupDelete: false,
      deleteRegulationImpl: {},
      checkImpl: [],
    };
  },
  mounted() {},
  props: {
    regulationDetailProps: {
      type: Object,
      default: null,
    },
    permissionProps: {
      type: Object,
      default: null,
    },
    manageRegulationProps: {
      type: Boolean,
      default: false,
    },
    verifiedRegulationProps: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update-reg-detail", "get-detail", "get-data-impl"],
  computed: {
    dataGrid: function () {
      return this.$refs[this.gridRef].instance;
    },
    ...mapGetters("regulationDetail", ["getRegulationDetail"]),
    // ...mapGetters("authentication", ["getUserDetail"]),
  },
  methods: {
    // siapkan ubah status menjadi proses ketika data dihapus
    confirmDestroy() {
      this.$store
        .dispatch(
          "regulationImpl/destroyRegulationImplementations",
          this.deleteRegulationImpl
        )
        .then((res) => {
          notify("delete success", "success", 2000);
          // this.getCountImplVerif();
          this.dataGrid.refresh();
          if (
            this.regulationDetailProps.status == "done" &&
            this.deleteRegulationImpl.verified
          ) {
            this.regulationDetailProps.status = "process";
            this.$emit("update-reg-detail", this.regulationDetailProps);
          }
        })
        .catch((e) => {
          notify(e.message, "error", 2000);
        });
      this.popupDelete = false;
    },
    popupDestroy(params) {
      // if (params.row.data.verified != null) {
      //   notify("Bukti implementasi sudah di verifikasi", "warning", 2000);
      //   return;
      // }
      this.popupDelete = true;
      this.deleteRegulationImpl = params.row.data;
    },
    dateColumn(cellInfo) {
      if (cellInfo.value) {
        const date = new Date(cellInfo.value);
        const dd = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
        const mounth = parseInt(date.getMonth()) + 1;
        const mm = mounth < 10 ? "0" + mounth : mounth;

        return dd + "/" + mm + "/" + date.getFullYear();
      }
    },
    // processStatus() {
    //   this.detail.status = "process";

    //   this.$emit("get-data-impl", this.detail);
    // },
    // getDetail() {
    //   this.$emit("get-detail", this.detail);
    // },
    updateRegImpl(params) {
      this.$store
        .dispatch("regulationImpl/updateRegulationImplementations", params)
        .then((res) => {
          // this.checkImpl(res.data.data.regulation_detail_id);
          const filterImpl = this.checkImpl?.filter(
            (item) => item.verified === true
          );

          if (filterImpl.length === this.regulationDetailProps.total_impl) {
            this.regulationDetailProps.status = "done";
            this.$emit("update-reg-detail", this.regulationDetailProps);
          }
        });
    },
    confirmVerification(params) {
      this.$store.dispatch(
        "regulationDetail/setRegulationDetail",
        this.regulationDetailProps
      );
      this.verifImpl.verification_date = new Date();
      this.verifImpl.verified = params;
      this.verifImpl._method = "PUT";
      // this.$emit("update-reg-detail", this.verifImpl);
      this.updateRegImpl(this.verifImpl);
      this.dataGrid.refresh();

      const filterImpl = this.checkImpl?.filter(
        (item) => item.verified === true
      );

      this.popupVisible = false;
    },
    popupVerified(params) {
      if (params.row.data.verified != null) {
        notify("Bukti implementasi sudah di verifikasi", "warning", 2000);
        return;
      } else {
        this.verifImpl = params.row.data;
        this.popupVisible = true;
      }
    },
    edit(params) {
      if (params.row.data.verified != null) {
        notify("Bukti implementasi sudah di verifikasi", "warning", 2000);
        return;
      }
      this.$store.dispatch("regulationImpl/setRegulationImpl", params.row.data);

      router.push(`/regulation-impl/form/edit/${params.row.key}`);
    },
    // async getCountImplVerif() {
    //   const text = `?regulation_detail=${this.regulationDetailProps.id}&null=verified`;
    //   const verifiedNull = await this.$store.dispatch(
    //     "regulationImpl/getRegulationImplementations",
    //     text
    //   );

    //   const text2 = `?regulation_detail=${this.regulationDetailProps.id}&verified=true`;
    //   const verifiedTrue = await this.$store.dispatch(
    //     "regulationImpl/getRegulationImplementations",
    //     text2
    //   );

    //   this.countImplVerif = verifiedNull.data.length + verifiedTrue.data.length;
    // },
    onToolbarPreparing(e) {
      const vThis = this;
      if (this.permissionProps.canCreate && this.manageRegulationProps) {
        e.toolbarOptions.items.unshift({
          location: "before",
          widget: "dxButton",
          options: {
            icon: "add",
            text: "Tambah",
            stylingMode: "outlined",
            type: "default",
            onInitialized: function (e) {
              this.btnAdd = e.component;
            },
            onClick: function () {
              const filteredData = vThis.checkImpl.filter(
                (item) => item.verified === null || item.verified === true
              );

              if (
                filteredData.length === vThis.regulationDetailProps.total_impl
              ) {
                notify("bukti implementasi sudah terpenuhi", "warning", 4000);
                return;
              }
              router.push(
                `/regulation-impl/form/add/${vThis.regulationDetailProps.id}`
              );
            },
          },
        });
      }
    },
    async fetch(params = {}) {
      // eslint-disable-next-line no-console
      if (Object.keys(params).length === 0) {
        params = {};
      }

      let text = "?";
      const keys = Object.keys(params);
      keys.forEach((i) => {
        if (i in params && isNotEmpty(params[i])) {
          text += `${i}=${JSON.stringify(params[i])}&`;
        }
      });
      text = text.slice(0, -1);

      var results = await this.$store.dispatch(
        "regulationImpl/getRegulationImplementations",
        text.replace(/['"]+/g, "")
      );
      console.log("RESULTS : ", results);
      this.checkImpl = results.data;
      var datax = {
        data: results.data,
        totalCount: results.paginate.total || 0,
      };
      return datax;
    },
  },
};
</script>
<style scoped>
.popup-property-details {
  overflow: hidden;
  position: relative;
}
.justify-center {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.customBtn {
  margin: 1%;
}
.link-icon {
  text-decoration: none;
  font-size: 18px;
}
.master-detail-caption {
  padding: 0 0 5px 10px;
  font-size: 14px;
  font-weight: bold;
}
.approve {
  background-color: green;
  color: white;
  font-weight: bold;
  text-align: center;
  border-radius: 25px;
  padding: 2%;
}
.reject {
  background-color: red;
  color: black;
  font-weight: bold;
  text-align: center;
  border-radius: 25px;
  padding: 2%;
}
</style>