<template>
  <div>
    <div class="content-block header">
      <h2 class="group"><i class="fa-solid fa-house-user"></i> Home</h2>
      <div class="group">
        <DxButton
          v-if="!loading"
          style="margin-top: 10px"
          text="Refresh"
          hint="Refresh"
          icon="fa fa-arrows-rotate"
          @click="refresh"
        />
        <DxLoadIndicator style="margin-top: 5px" v-else />
      </div>
    </div>

    <div class="container-message">
      <h1 class="thank-you-message">
        Halo, {{ getUserDetail.full_name }}! Login berhasil, Kami senang Anda
        kembali.
      </h1>
    </div>

    <div class="content-block">
      <div class="card-grid">
        <div class="card-item" v-for="(card, index) in dashboard" :key="index">
          <div :width="'100%'" class="dx-card card">
            <div class="card-content">
              <div class="card-left">
                <i :class="card.dashboard.icon" class="card-icon"></i>
                <!-- <i :class="[card.icon]" class="card-icon"></i> -->
              </div>
              <div class="card-right">
                <div class="data-count">
                  {{ vDashboard[card.dashboard.data_name] }}
                </div>
                <div class="dashboard-name">{{ card.dashboard.name }}</div>
                <div class="card-button">
                  <DxButton
                    height="20px"
                    text="detail"
                    :type="card.dashboard.color"
                    @click="direct(card.dashboard.path)"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DxButton from "devextreme-vue/button";
import { mapGetters, mapActions } from "vuex";
import notify from "devextreme/ui/notify";
import { DxPopup, DxPosition } from "devextreme-vue/popup";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";
import router from "@/router";
import Api from "@/services/Api";
import CryptoJS from "crypto-js";
import { DxTextBox } from "devextreme-vue/text-box";

export default {
  data() {
    return {
      vDashboard: {},
      positionOf: "",
      interval: null,
      isPageVisible: true,
      loading: true,
      dashboard: [],
      validateDashboard: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters("authentication", [
      "getUserDetail",
      "getUser",
      "getPopUpHome",
    ]),
    checkStorage() {
      if (
        localStorage.getItem("m_dpt") &&
        localStorage.getItem("m_sec") &&
        localStorage.getItem("m_unt") &&
        localStorage.getItem("m_bran")
      ) {
        return true;
      }
      return false;
    },
  },
  props: {
    roles: {
      type: String,
    },
  },
  created() {
    this.getDashboard();
  },
  mounted() {
    this.loading = true;
    if (!this.checkStorage) {
      this.getBranchs();
      this.getSections();
      this.getDepartments();
      this.getUnitBinis();
    }
  },
  methods: {
    async getDashboard() {
      const dash = await this.$store.dispatch(
        "userDashboardAccess/getDashboards",
        ""
      );

      let permission_dashboard = dash.dashboard_detail.permission_dashboard;
      permission_dashboard.sort(
        (a, b) => a.dashboard.order - b.dashboard.order
      );

      this.dashboard = permission_dashboard;
      this.vDashboard = dash.count_dashboard;
      this.loading = false;
    },
    handleVisibilityChange() {
      this.isPageVisible = !document.hidden;
    },
    async getBranchs() {
      await this.$store.dispatch("branch/getAllBranch", "?limit=9999");
    },
    async getSections() {
      await this.$store.dispatch("section/getAllSection", "?");
    },
    async getDepartments() {
      await this.$store.dispatch("department/getAllDepartment", "?");
    },
    async getUnitBinis() {
      await this.$store.dispatch("unitBisnis/getAllUnitBisnis", "?");
    },
    refresh() {
      this.loading = true;
      this.getDashboard();
      this.getBranchs();
      this.getSections();
      this.getDepartments();
      this.getUnitBinis();
    },
    direct(value) {
      router.push(value);
    },
  },
  components: {
    DxButton,
    DxPopup,
    DxPosition,
    DxLoadIndicator,
    DxTextBox,
  },
};
</script>
  
  <style lang="scss" scoped>
.btn-refresh {
  margin-top: 10px;
}
.header {
  justify-content: space-between;
  display: flex;
  margin-bottom: 25px;
  margin-top: 10px;
}
.group {
  float: left;
  margin: 10px;
}
.customBtn {
  margin: 1%;
}
.popup-property-details {
  overflow: hidden;
  position: relative;
}
.row:after {
  content: "";
  display: table;
  clear: both;
}

.column {
  float: left;
  width: 22%;
  margin: 10px;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); /* this adds the "card" effect */
  padding: 16px;
  text-align: center;
  background-color: #f1f1f1;
}

@media screen and (max-width: 600px) {
  .column {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
}

.logos-container {
  margin: 20px 0 40px 0;
  text-align: center;
  svg {
    display: inline-block;
  }
}

.devextreme-logo {
  width: 200px;
  height: 34px;
  margin-bottom: 17px;
}

.vue-logo {
  width: 180px;
  height: 62px;
}

.plus {
  margin: 20px 10px;
  width: 22px;
  height: 22px;
}

.screen-x-small .logos-container {
  svg {
    width: 100%;
    display: block;
    &.plus {
      margin: 0;
    }
  }
}
.dx-button.dx-button-warning {
  background-color: hsl(60, 100%, 50%);
}

/* BARU */
.card-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
}

.card-item {
  flex: 1 1 calc(25% - 20px);
  box-sizing: border-box;
  min-width: 200px;
}

.card {
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.card-content {
  display: flex;
  width: 100%;
}

.card-left {
  font-size: 25px;
  color: #3498db;
  margin-right: 10px;
}

.card-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
}

.data-count {
  font-size: 20px;
  font-weight: bold;
}

.dashboard-name {
  font-size: 16px;
  margin-top: 5px;
}

.card-button {
  margin-top: 5px;
}

.card-button .btn {
  background-color: #3498db;
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  text-decoration: none;
}

.card-button .btn:hover {
  background-color: #2980b9;
}

@media (max-width: 768px) {
  .card-item {
    flex: 1 1 calc(50% - 20px);
    min-width: 200px;
  }
}

@media (max-width: 480px) {
  .card-item {
    flex: 1 1 calc(33% - 20px);
    min-width: 200px;
  }
}

/* Container utama */
.container-message {
  text-align: center;
  color: grey;
}

/* Ucapan Terima Kasih dengan animasi */
.thank-you-message {
  font-size: 1.5rem;
  font-weight: bold;
  animation: moveMessage 15s ease-in-out infinite;
}

/* Animasi untuk ucapan terima kasih */
@keyframes moveMessage {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  50% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(100%);
    opacity: 0;
  }
}
</style>
  