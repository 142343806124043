<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <form action="/formAuditPlan" @submit.prevent="handleSubmit">
        <DxSelectBox
          class="custom-form"
          :data-source="agendaTypeOptions"
          label="Pilih Tipe Agenda *"
          value-expr="name"
          display-expr="name"
          :is-required="true"
          @valueChanged="changeAgendaType"
          v-model="valueAgendaType"
          :read-only="readOnlyAgendaType"
        >
          <DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxSelectBox>

        <DxTextBox
          class="custom-form"
          label="Nama Agenda *"
          v-model:value="auditPlan.event_name"
          :is-required="true"
          ><DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxTextBox>

        <DxCheckBox
          v-if="!disableAgenda"
          text="All Unit & Department"
          :value="allDepartment"
          class="custom-form"
          @valueChanged="checkboxDepartment"
        />

        <DxTagBox
          v-if="!allDepartment"
          class="custom-form"
          :search-enabled="true"
          :data-source="allDepartmentOption"
          label="Pilih Department *"
          display-expr="department_name"
          value-expr="department_id"
          :value="valueAllDepart"
          @value-changed="onValueChanged"
          :maxFilterQueryLength="9999"
          item-template="item"
          :read-only="allDepartment"
        >
          <template #item="{ data: item }">
            <div class="custom-item">
              <div class="product-name">
                {{ item.department_name }} <b>|| {{ item.company_name }}</b>
              </div>
            </div>
          </template>
          <DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxTagBox>

        <DxSelectBox
          v-if="disableAgenda"
          class="custom-form"
          :data-source="unitBisnisOptions"
          label="Pilih Unit Bisnis *"
          value-expr="id"
          display-expr="name"
          :is-required="true"
          @valueChanged="changeUnitBisnis"
          v-model:value="auditPlan.company_id"
        >
          <!-- :read-only="paramId && paramId.trim() !== ''" -->
          <DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxSelectBox>
        <div v-if="disableAgenda && enableSelect">
          <DxSelectBox
            v-if="disableAgenda"
            class="custom-form"
            :data-source="departmentOptions"
            label="Pilih Department *"
            value-expr="department_id"
            display-expr="department_name"
            v-model:value="auditPlan.department_id"
            :is-required="true"
            @valueChanged="changeDepartment"
            :read-only="readOnlyDept"
          >
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxSelectBox>
          <DxSelectBox
            v-if="disableAgenda"
            class="custom-form"
            :data-source="sectionOptions"
            label="Pilih Section"
            value-expr="section_id"
            display-expr="section_name"
            v-model:value="auditPlan.section_id"
            :is-required="true"
            :read-only="readOnlySec"
            :show-clear-button="true"
          >
            <!-- <DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator> -->
          </DxSelectBox>
          <DxSelectBox
            v-if="disableAgenda"
            class="custom-form"
            :data-source="branchOptions"
            label="Pilih Lokasi *"
            value-expr="location_id"
            display-expr="location_name"
            v-model:value="auditPlan.branch_id"
            :is-required="true"
            :search-enabled="true"
            search-expr="location_name"
            :read-only="readOnlyBran"
          >
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxSelectBox>
        </div>

        <div
          v-if="
            disableAgenda && paramId && paramId.trim() !== '' && readOnlyDept
          "
        >
          <DxTextBox
            class="custom-form"
            label="department"
            v-model:value="auditPlan.department_name"
            :read-only="true"
          >
          </DxTextBox>
          <DxTextBox
            class="custom-form"
            label="Section"
            v-model:value="auditPlan.section_name"
            :read-only="true"
          >
          </DxTextBox>
          <DxTextBox
            class="custom-form"
            label="Lokasi"
            v-model:value="auditPlan.branch_name"
            :read-only="true"
          >
          </DxTextBox>
        </div>
        <!-- </div> -->

        <DxTextBox
          v-if="disableAgenda"
          class="custom-form"
          label="Partisipasi *"
          v-model:value="auditPlan.participant"
          :is-required="true"
          ><DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxTextBox>

        <div class="input-group">
          <DxSelectBox
            v-if="disableAgenda"
            class="group"
            :data-source="tipeAuditOptions"
            label="Pilih Tipe Audit *"
            value-expr="name"
            display-expr="name"
            :is-required="true"
            v-model="auditPlan.audit_type"
          >
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxSelectBox>
          <DxSelectBox
            class="group"
            :data-source="methodOptions"
            label="Pilih Methode *"
            value-expr="name"
            display-expr="name"
            :is-required="true"
            v-model="auditPlan.method"
          >
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxSelectBox>
          <DxSelectBox
            v-if="disableAgenda"
            class="group"
            :data-source="criteriaAuditOptions"
            label="Pilih Kriteria Audit *"
            value-expr="name"
            display-expr="name"
            :is-required="true"
            v-model="auditPlan.audit_criteria"
          >
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxSelectBox>
        </div>

        <div class="input-group">
          <DxDateBox
            class="group"
            label="Tanggal Mulai *"
            :input-attr="{ 'aria-label': 'Date' }"
            type="datetime"
            v-model:value="auditPlan.start_date"
            display-format="d / MM / yyyy HH:mm"
            :show-clear-button="true"
            :use-mask-behavior="true"
          >
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxDateBox>
          <DxDateBox
            class="group"
            label="Tanggal Berakhir *"
            :input-attr="{ 'aria-label': 'Date' }"
            type="datetime"
            v-model:value="auditPlan.end_date"
            display-format="d / MM / yyyy HH:mm"
            :show-clear-button="true"
            :use-mask-behavior="true"
          >
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxDateBox>
          <DxSelectBox
            v-if="disableAgenda"
            class="group"
            :data-source="categoryAuditOptions"
            label="Pilih Kategori Audit *"
            value-expr="name"
            display-expr="name"
            :is-required="true"
            v-model="auditPlan.audit_category"
          >
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxSelectBox>
        </div>
        <div class="repeat-container" v-if="!paramId && paramId.trim() == ''">
          <!-- Radio buttons untuk memilih interval ulang -->
          <dx-radio-group
            v-model="repeatType"
            :items="repeatOptions"
            @value-changed="onRepeatTypeChange"
            displayExpr="label"
            valueExpr="value"
            layout="horizontal"
          >
          </dx-radio-group>

          <!-- Form inputan untuk bulan -->
          <div v-if="repeatType === 'monthly'" class="input-repeat">
            <dx-number-box
              v-model="repeatMonths"
              :min="1"
              :max="12"
              :step="1"
              placeholder="Masukkan jumlah bulan"
              width="30%"
              class="repeat"
            />
            <span class="repeat">Bulan</span>
            <DxButton
              type="danger"
              icon="close"
              class="repeat"
              @click="onResetRepeat"
            />
          </div>

          <!-- Form inputan untuk tahun -->
          <div v-if="repeatType === 'yearly'" class="input-repeat">
            <dx-number-box
              v-model="repeatYears"
              :min="1"
              :max="5"
              :step="1"
              placeholder="Masukkan jumlah tahun"
              width="30%"
              class="repeat"
            />
            <span class="repeat">Tahun</span>
            <DxButton
              type="danger"
              icon="close"
              class="repeat"
              @click="onResetRepeat"
            />
          </div>
        </div>

        <DxButton
          v-if="!loading"
          text="Simpan Data"
          type="success"
          icon="save"
          :use-submit-behavior="true"
        />
        <DxLoadIndicator v-else />
      </form>
    </div>
  </div>
</template>
              
        <script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxEmailRule,
} from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { mapGetters, mapActions } from "vuex";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";
import DxDateBox from "devextreme-vue/date-box";
import { DxCheckBox } from "devextreme-vue/check-box";
import DxTagBox from "devextreme-vue/tag-box";
import DxRadioGroup from "devextreme-vue/radio-group";
import { DxNumberBox } from "devextreme-vue/number-box";

import notify from "devextreme/ui/notify";
import axios from "axios";
import router from "@/router";

export default {
  data() {
    return {
      loading: false,
      loadIndicatorVisible: false,
      auditPlan: {},
      tipeAuditOptions: [{ name: "Internal" }, { name: "Eksternal" }],
      methodOptions: [{ name: "Offline" }, { name: "Online" }],
      criteriaAuditOptions: [{ name: "QMS" }, { name: "HSE" }],
      categoryAuditOptions: [{ name: "Terbuka" }, { name: "Tertutup" }],
      unitBisnisOptions: "",
      departmentOptions: "",
      sectionOptions: "",
      branchOptions: "",
      agendaTypeOptions: [{ name: "Agenda Audit" }, { name: "Agenda Meeting" }],
      valueAgendaType: "",
      readOnlyAgendaType: false,
      readOnlyDept: true,
      readOnlySec: true,
      readOnlyBran: true,
      allDepartment: true,
      allDepartmentOption: [],
      valueAllDepart: [],
      disabledTag: true,
      loadingTag: true,
      valueDepartment: "",
      nameDepart: "",
      paramId: this.$route.params.id,
      enableSelect: true,
      repeatType: null, // default pilihan pertama adalah bulanan
      repeatMonths: null, // default pengulangan 1 bulan
      repeatYears: null, // default pengulangan 1 tahun
      repeatOptions: [
        { value: "monthly", label: "Ulangi Bulan Depan" },
        { value: "yearly", label: "Ulangi Tahun Depan" },
      ],
    };
  },
  emits: ["get-audit-plan"],
  computed: {
    ...mapGetters("auditPlan", ["getAuditPlan", "getAuditPlanStatus"]),
    ...mapGetters("authentication", ["getUserDetail"]),
    ...mapGetters("branch", ["getAllBranch"]),
    ...mapGetters("section", ["getAllSection"]),
    ...mapGetters("department", ["getAllDepartment"]),
    ...mapGetters("unitBisnis", ["getAllUnitBisnis"]),
    disableAgenda() {
      if (this.valueAgendaType != "Agenda Audit") {
        return false;
      }
      return true;
    },
    validateTag() {
      if (!this.disabledTag) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    if (this.paramId && this.paramId.trim() !== "") {
      this.enableSelect = false;
      if (this.getAuditPlan) {
        this.auditPlan = this.getAuditPlan;
        this.valueAgendaType = this.getAuditPlan.agenda_type;
        this.readOnlyAgendaType = true;
        this.allDepartment = this.getAuditPlan.all;

        const array = this.getAuditPlan.meeting_participants;
        this.valueAllDepart = array
          ? array.split(",").map((item) => item.trim())
          : [];
      } else {
        this.loadAgenda(this.paramId);
      }
    }

    this.unitBisnisOptions = this.getAllUnitBisnis;
    this.allDepartmentOption = this.getAllDepartment;
  },
  methods: {
    onResetRepeat() {
      this.repeatType = null;
      this.repeatMonths = null;
      this.repeatYears = null;
    },
    onRepeatTypeChange(event) {
      // Reset inputan saat tipe ulang berubah
      if (event.value === "monthly") {
        this.repeatMonths = null;
      } else if (event.value === "yearly") {
        this.repeatYears = null;
      }
    },
    async loadAgenda(params) {
      try {
        const getAgenda = await this.$store.dispatch(
          "auditPlan/getAuditPlan",
          `/${params}`
        );
        this.auditPlan = getAgenda.data;
        this.valueAgendaType = getAgenda.data.agenda_type;
        this.readOnlyAgendaType = true;
        this.allDepartment = getAgenda.data.all;

        const dep = this.getAllDepartment.find(
          (res) => res.department_id == getAgenda.data.department_id
        );
        this.auditPlan.company_name = dep.company_name;
        this.auditPlan.department_name = dep.department_name;

        if (getAgenda.data.section_id) {
          const sec = this.getAllSection.find(
            (res) => res.section_id == getAgenda.data.section_id
          );
          this.auditPlan.section_name = sec.section_name;
        }

        const bran = this.getAllBranch.find(
          (res) => res.location_id == getAgenda.data.branch_id
        );
        this.auditPlan.branch_name = bran.location_name;

        const array = getAgenda.data.meeting_participants;
        this.valueAllDepart = array
          ? array.split(",").map((item) => item.trim())
          : [];
      } catch (error) {
        notify(error, "error", 2000);
      }
    },

    // async getDepartment() {
    //   const departments = await this.$store.dispatch(
    //     "department/getDepartments",
    //     `?`
    //   );

    //   this.allDepartmentOption = departments.data;
    // },
    onValueChanged(e) {
      let related = "";
      related = e.value;
      this.auditPlan.meeting_participants = related.join();
    },
    checkboxDepartment(params) {
      // this.disabledTag = params.value;
      this.allDepartment = params.value;

      if (params.value == true) {
        this.valueAllDepart = [];
        this.auditPlan.meeting_participants = "";
      }
    },
    changeAgendaType(params) {
      this.auditPlan.agenda_type = params.value;
      if (params.value == "Agenda Audit") {
        this.auditPlan.status = false;
        this.allDepartment = true;
        this.auditPlan.meeting_participants = null;
      }
    },
    async getBranch(params) {
      // const getBranch = await this.$store.dispatch(
      //   "branch/getBranch",
      //   `?limit=9999&company=${params}`
      // );
      // this.branchOptions = getBranch.data;
      const getBranch = this.getAllBranch?.filter(
        (e) => e.company_id == params
      );
      this.branchOptions = getBranch;
    },
    async changeDepartment(params) {
      // const text = `?department=${params.value}&limit=9999`;

      // const getSection = await this.$store.dispatch("section/getSection", text);
      // this.sectionOptions = getSection.data;
      const getSection = this.getAllSection?.filter(
        (e) => e.department_id == params.value
      );
      this.sectionOptions = getSection;

      this.getBranch(this.auditPlan.company_id);

      this.auditPlan.section_id ? (this.auditPlan.section_id = null) : "";

      this.readOnlySec = false;
      this.readOnlyBran = false;
    },
    async changeUnitBisnis(params) {
      const depart = this.getAllDepartment?.filter(
        (e) => e.company_id == params.value
      );

      this.readOnlyDept = false;
      this.departmentOptions = depart;

      this.auditPlan.department_id ? (this.auditPlan.department_id = null) : "";
      this.auditPlan.section_id ? (this.auditPlan.section_id = null) : "";
      this.auditPlan.branch_id ? (this.auditPlan.branch_id = null) : "";
      if (this.paramId && this.paramId.trim() != "") {
        this.enableSelect = true;
      }
    },
    // async unitBisnis() {
    //   const units = await this.$store.dispatch("unitBisnis/getUnits", `?`);
    //   this.unitBisnisOptions = units.data;
    // },
    handleSubmit(e) {
      this.loading = true;
      this.auditPlan.initiated_by = this.getUserDetail.id;
      if (this.auditPlan.agenda_type == "Agenda Meeting") {
        this.auditPlan.all = this.allDepartment;
      }
      if (!this.repeatMonths && !this.repeatYears) {
        this.$emit("get-audit-plan", this.auditPlan);
        return;
      }
      const array = [];
      array.push(this.auditPlan);
      if (this.repeatMonths) {
        for (let i = 1; i <= this.repeatMonths; i++) {
          // Salin objek auditPlan hanya sekali
          let newStartDate = new Date(this.auditPlan.start_date);
          let newEndDate = new Date(this.auditPlan.end_date);

          // Tambahkan bulan pada start_date dan end_date
          newStartDate.setMonth(newStartDate.getMonth() + i);
          newEndDate.setMonth(newEndDate.getMonth() + i);

          // Masukkan objek baru ke dalam array
          array.push({
            ...this.auditPlan, // Menambahkan properti lainnya dari auditPlan
            start_date: newStartDate,
            end_date: newEndDate,
          });
        }
      } else if (this.repeatYears) {
        for (let i = 1; i <= this.repeatYears; i++) {
          let newStartDate = new Date(this.auditPlan.start_date);
          let newEndDate = new Date(this.auditPlan.end_date);

          newStartDate.setFullYear(newStartDate.getFullYear() + i);
          newEndDate.setFullYear(newEndDate.getFullYear() + i);

          array.push({
            ...this.auditPlan,
            start_date: newStartDate,
            end_date: newEndDate,
          });
        }
      }
      const data = {
        audit_plans: array,
      };
      console.log("CHECK ARRAY:", data);
      this.$emit("get-audit-plan", data);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxEmailRule,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    DxLoadIndicator,
    DxDateBox,
    DxCheckBox,
    DxTagBox,
    DxRadioGroup,
    DxNumberBox,
  },
};
</script>
        
        <style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}

#button .button-indicator {
  height: 32px;
  width: 32px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}
.center {
  justify-content: space-between;
  display: flex;
  margin-bottom: 25px;
  margin-top: 10px;
}
.group {
  display: inline-block;
}
.input-group {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(150px, 1fr)
  ); /* Auto-fit columns with a minimum width */
  gap: 10px; /* Optional: space between input elements */
  margin-bottom: 25px;
  margin-top: 10px;
}

.input-group .input-field {
  width: 100%;
}
.repeat-container {
  display: flex;
  flex-direction: column; /* Mengatur form secara vertikal */
  align-items: flex-end; /* Memindahkan konten ke kanan */
  width: 100%;
  margin-top: 10px;
  margin-bottom: 25px;
}

.input-repeat {
  display: flex;
  align-items: center;
  justify-content: flex-end; /* Memindahkan form input ke kanan */
  margin-bottom: 10px; /* Memberikan jarak antara elemen input */
}
.repeat {
  margin: 0px 5px;
}
</style>