<template>
  <div class="content-block dx-card responsive-paddings">
    <form action="/addRegulationList" @submit.prevent="handleSubmit">
      <div class="input-group">
        <DxSelectBox
          class="group"
          :data-source="requirementTypeOption"
          label="Pilih Tipe Kebutuhan"
          v-model="regulationImpl.requirement_type"
          :is-required="true"
        >
          <DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxSelectBox>

        <DxTextBox
          class="group"
          label="Detail"
          v-model:value="regulationImpl.requirement_detail"
          :is-required="true"
          ><DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxTextBox>
      </div>

      <div>
        <div class="label-text">Bukti Implementasi Regulasi</div>
        <div class="fileuploader-container custom-form">
          <DxFileUploader
            select-button-text="Pilih Dokumen"
            label-text="upload file *"
            :allowed-file-extensions="fileAllowed"
            :max-file-size="6000000"
            upload-mode="useForm"
            @value-changed="getFile"
            :validationError="validationError"
          >
          </DxFileUploader>
        </div>
        <div v-if="validationError" style="color: red">File is required.</div>
        <div v-if="paramsRoute.condition == 'edit' && !file">
          File Lama : {{ regulationImpl.pod_file }}
        </div>
        <div style="text-align: right; margin-bottom: 10px">
          <span>{{ fileAllowed }}</span> - <span>Max : 5 MB</span>.
        </div>
      </div>

      <DxButton
        v-if="!loading"
        text="Simpan Data"
        type="success"
        icon="save"
        :use-submit-behavior="true"
        style="margin: 1%"
      />
      <DxLoadIndicator v-else />
      <!-- <DxButton
        v-if="!loading"
        text="Skip"
        type="default"
        icon="fa fa-forward"
        style="margin: 1%"
        @click="skip"
      /> -->
    </form>
  </div>
</template>
        
        <script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxEmailRule,
} from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { DxFileUploader } from "devextreme-vue/file-uploader";
import FilterTableUnitBisnis from "@/components/partials/FilterTableUnitBisnis.vue";
import { mapGetters, mapActions } from "vuex";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";
import router from "@/router";

export default {
  data() {
    return {
      requirementTypeOption: ["Proses Area", "Fasilitas", "Dokumen Kerja"],
      loading: false,
      regulationImpl: {},
      file: "",
      fileAllowed: [".docx", ".doc", ".pdf", ".jpg", ".jpeg", ".png"],
      validationError: false,
      paramsRoute: this.$route.params,
    };
  },
  mounted() {
    if (
      this.paramsRoute.condition == "edit" &&
      this.paramsRoute.id.trim() !== ""
    ) {
      if (this.getRegulationImpl) {
        this.regulationImpl = this.getRegulationImpl;
      } else {
        this.loadRegulationImpl(this.paramsRoute.id);
      }
    }
  },
  computed: {
    ...mapGetters("regulationDetail", ["getRegulationDetail"]),
    ...mapGetters("regulationImpl", [
      "getRegulationImpl",
      "getRegulationImplStatus",
    ]),
  },
  emits: ["get-regulation-impl", "cancle"],
  methods: {
    skip() {
      router.push("/regulation-manage/info");
    },
    async loadRegulationImpl(params) {
      try {
        const getRegImpl = await this.$store.dispatch(
          "getRegulationImpl/getRegulationImplementations",
          `/${params}`
        );
        this.regulationImpl = getRegImpl.data;
      } catch (error) {
        notify(error, "error", 2000);
      }
    },
    getFile(params) {
      if (params.component._files.length > 0) {
        this.loading = !params.component._files[0].isValidFileExtension;
        this.file = params.value[0];

        this.validationError = false;
      }
    },
    async handleSubmit(e) {
      if (
        this.paramsRoute.condition == "add" ||
        this.paramsRoute.condition == "redirect"
      ) {
        if (!this.file) {
          this.validationError = true; // Set validation error if no files
          return;
        }
      }
      this.regulationImpl.pod_file = this.file;

      this.loading = true;
      this.$emit("get-regulation-impl", this.regulationImpl);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxEmailRule,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    FilterTableUnitBisnis,
    DxFileUploader,
    DxLoadIndicator,
  },
};
</script>
        
        <style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
.fileuploader-container {
  border: 1px solid #d3d3d3;
  margin: 0;
}
.label-text {
  color: darkgray;
  display: block;
}
.group {
  display: inline-block;
}
.input-group {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(150px, 1fr)
  ); /* Auto-fit columns with a minimum width */
  gap: 10px; /* Optional: space between input elements */
  margin-bottom: 25px;
  margin-top: 10px;
}

.input-group .input-field {
  width: 100%;
}
</style>