<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <form action="/addRiskControlItem" @submit.prevent="handleSubmit">
        <div v-for="(form, index) in item" :key="index">
          <div class="input-group">
            <DxTextBox
              class="group"
              label="Nama"
              v-model:value="form.name"
              :is-required="true"
              ><DxValidator>
                <DxRequiredRule message="Required" />
              </DxValidator>
            </DxTextBox>

            <DxSelectBox
              class="group"
              :data-source="typeOptions"
              label="Pilih Tipe"
              v-model="form.type"
              :is-required="true"
            >
              <DxValidator>
                <DxRequiredRule message="Required" />
              </DxValidator>
            </DxSelectBox>
          </div>
          <DxTextArea
            class="custom-form"
            label="Deskripsi"
            :height="90"
            :input-attr="{ 'aria-label': 'Notes' }"
            v-model:value="form.description"
          >
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxTextArea>
          <DxButton
            v-if="item.length > 1"
            class="customBtn"
            type="danger"
            icon="fa fa-minus"
            @click="removeForm(index)"
          />
        </div>

        <div v-if="!loading">
          <DxButton
            v-if="!paramId && item.length < 10"
            class="customBtn"
            text="Form"
            type="default"
            icon="fa fa-plus"
            @click="addForm"
          />

          <DxButton
            text="Simpan Data"
            type="success"
            icon="save"
            :use-submit-behavior="true"
          />
        </div>
        <DxLoadIndicator v-else />
      </form>
    </div>
  </div>
</template>
        
  <script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxEmailRule,
} from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { mapGetters, mapActions } from "vuex";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";

import notify from "devextreme/ui/notify";
import axios from "axios";
import router from "@/router";
import FilterTableUnitBisnis from "@/components/partials/FilterTableUnitBisnis.vue";

export default {
  data() {
    return {
      loading: false,
      item: [
        {
          name: "",
          type: "",
          description: "",
        },
      ],
      typeOptions: ["Pengendalian Risiko", "Keparahan Risiko"],
      paramId: this.$route.params.id,
    };
  },
  mounted() {
    if (this.paramId && this.paramId.trim() !== "") {
      if (this.getRiskControlItem) {
        this.item[0] = this.getRiskControlItem;
      } else {
        this.loadRiskControlItem(this.paramId);
      }
    }
  },
  emits: ["get-risk-control-item"],
  computed: {
    ...mapGetters("riskControlItem", [
      "getRiskControlItem",
      "getRiskControlItemStatus",
    ]),
  },
  methods: {
    async loadRiskControlItem(params) {
      // reload
      try {
        const controlItem = await this.$store.dispatch(
          "riskControlItem/getRiskControlItem",
          `/${params}`
        );
        this.item[0] = controlItem.data;
      } catch (error) {
        notify(error, "error", 2000);
      }
    },
    addForm() {
      this.item.push({
        name: "",
        type: "",
        description: "",
      });
    },
    removeForm(index) {
      this.item.splice(index, 1);
    },
    handleSubmit(e) {
      this.loading = true;
      this.$emit("get-risk-control-item", this.item);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxEmailRule,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    FilterTableUnitBisnis,
    DxLoadIndicator,
  },
};
</script>
  
  <style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
.group {
  display: inline-block;
}
.input-group {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(150px, 1fr)
  ); /* Auto-fit columns with a minimum width */
  gap: 10px; /* Optional: space between input elements */
  margin-bottom: 25px;
  margin-top: 10px;
}

.input-group .input-field {
  width: 100%;
}
.customBtn {
  margin: 1%;
}
</style>