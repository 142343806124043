<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <form action="/addRisk" @submit.prevent="handleSubmit">
        <DxTextArea
          class="custom-form"
          label="Proses / Aktivitas"
          :height="90"
          :input-attr="{ 'aria-label': 'Notes' }"
          v-model:value="risk.process_name"
        >
          <DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxTextArea>

        <DxButton
          v-if="!loading"
          text="Simpan Data"
          type="success"
          icon="save"
          :use-submit-behavior="true"
        />
        <DxLoadIndicator v-else />
      </form>
    </div>
  </div>
</template>
            
      <script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxEmailRule,
} from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { mapGetters, mapActions } from "vuex";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";

import notify from "devextreme/ui/notify";
import axios from "axios";
import router from "@/router";
import FilterTableUnitBisnis from "@/components/partials/FilterTableUnitBisnis.vue";

export default {
  data() {
    return {
      loading: false,
      loadIndicatorVisible: false,
      buttonText: "Simpan",
      risk: {},
      unitBisnisOptions: "",
      departmentOptions: "",
      readOnlyDept: true,
      paramId: this.$route.params.id,
    };
  },
  mounted() {
    if (this.paramId && this.paramId.trim() !== "") {
      if (this.getRisk) {
        this.risk = this.getRisk;
      } else {
        this.loadRisk(this.paramId);
      }
    }
  },
  emits: ["get-risk"],
  computed: {
    ...mapGetters("risk", ["getRisk", "getRiskStatus"]),
    ...mapGetters("authentication", ["getUserDetail"]),
  },
  methods: {
    async loadRisk(params) {
      // reload
      try {
        const risk = await this.$store.dispatch("risk/getRisk", `/${params}`);
        this.risk = risk.data;
      } catch (error) {
        notify(error, "error", 2000);
      }
    },
    handleSubmit(e) {
      this.risk.department_id = this.getUserDetail.department_id;
      this.loading = true;
      this.$emit("get-risk", this.risk);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxEmailRule,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    DxLoadIndicator,
    FilterTableUnitBisnis,
  },
};
</script>
      
      <style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}

#button .button-indicator {
  height: 32px;
  width: 32px;
  display: inline-block;
  vertical-align: middle;
  margin-right: 5px;
}
</style>