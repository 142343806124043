<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <form action="/addRiskControlItem" @submit.prevent="handleSubmit">
        <div v-for="(form, index) in indicator" :key="index">
          <div class="input-group">
            <DxNumberBox
              class="group"
              label="Nilai"
              :min="0"
              :show-spin-buttons="true"
              :input-attr="{ 'aria-label': 'Min And Max' }"
              v-model:value="form.score"
            />
            <DxTextBox
              class="group"
              label="Nama"
              v-model:value="form.name"
              :is-required="true"
              ><DxValidator>
                <DxRequiredRule message="Required" />
              </DxValidator>
            </DxTextBox>
          </div>
          <DxButton
            v-if="indicator.length > 1"
            class="customBtn"
            type="danger"
            icon="fa fa-minus"
            @click="removeForm(index)"
          />
        </div>

        <div v-if="!loading">
          <DxButton
            v-if="paramsRoute.condition == 'add' && indicator.length < 10"
            class="customBtn"
            type="default"
            icon="fa fa-plus"
            @click="addForm"
          />

          <DxButton
            text="Simpan Data"
            type="success"
            icon="save"
            :use-submit-behavior="true"
          />
        </div>
        <DxLoadIndicator v-else />
      </form>
    </div>
  </div>
</template>
          
    <script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxEmailRule,
} from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { mapGetters, mapActions } from "vuex";
import { DxNumberBox } from "devextreme-vue/number-box";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";

import notify from "devextreme/ui/notify";

export default {
  data() {
    return {
      loading: false,
      indicator: [
        {
          score: 0,
          name: "",
        },
      ],
      paramsRoute: this.$route.params,
    };
  },
  mounted() {
    if (
      this.paramsRoute.condition == "edit" &&
      this.paramsRoute.id.trim() !== ""
    ) {
      if (this.riskScoreIndicator) {
        this.indicator[0] = this.riskScoreIndicator;
      } else {
        this.loadScoreIndicator(this.paramsRoute.id);
      }
    }
  },
  emits: ["get-risk-score-indicator"],
  computed: {
    ...mapGetters("riskControlItem", ["getRiskControlItem"]),
    ...mapGetters("riskScoreIndicator", [
      "getRiskScoreIndicator",
      "getRiskScoreIndicatorStatus",
    ]),
  },
  methods: {
    async loadScoreIndicator(params) {
      try {
        const scoreIndicator = await this.$store.dispatch(
          "riskScoreIndicator/getRiskScoreIndicator",
          `/${params}`
        );

        this.indicator[0] = scoreIndicator.data;
      } catch (error) {
        notify(error, "error", 2000);
      }
    },
    addForm() {
      this.indicator.push({
        score: 0,
        name: "",
      });
    },
    removeForm(index) {
      this.indicator.splice(index, 1);
    },
    handleSubmit(e) {
      this.loading = true;
      this.$emit("get-risk-score-indicator", this.indicator);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxEmailRule,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    // FilterTableUnitBisnis,
    DxNumberBox,
    DxLoadIndicator,
  },
};
</script>
    
    <style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
.customBtn {
  margin: 1%;
}
.group {
  display: inline-block;
}
.input-group {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(150px, 1fr)
  ); /* Auto-fit columns with a minimum width */
  gap: 10px; /* Optional: space between input elements */
  margin-bottom: 25px;
  margin-top: 10px;
}

.input-group .input-field {
  width: 100%;
}
</style>