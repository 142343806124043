<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <b>Detail Temuan (Refrensi) :</b>
      <div v-for="(data, index) in auditGroupFinding" :key="data.id">
        <div>
          {{ index + 1 }} . {{ data.finding_desc }} <b>({{ data.refrensi }})</b>
        </div>
      </div>
    </div>
    <div class="content-block dx-card responsive-paddings">
      <form action="/addAuditRootCause" @submit.prevent="handleSubmit">
        <div v-for="(form, index) in auditRootCause" :key="index">
          <div class="input-group">
            <p v-if="example[index]">
              {{ example[index].why }}
            </p>
            <p v-if="example[index]">
              {{ example[index].analys }}
            </p>
          </div>
          <div class="input-group">
            <DxTextArea
              class="custom-form"
              label="Metode 5 Why *"
              :height="90"
              :input-attr="{ 'aria-label': 'Notes' }"
              v-model:value="form.question"
            >
              <DxValidator>
                <DxRequiredRule message="Required" />
              </DxValidator>
            </DxTextArea>

            <DxTextArea
              class="custom-form"
              label="Analisa Akar Masalah *"
              :height="90"
              :input-attr="{ 'aria-label': 'Notes' }"
              v-model:value="form.answer"
            >
              <DxValidator>
                <DxRequiredRule message="Required" />
              </DxValidator>
            </DxTextArea>
          </div>
          <DxButton
            v-if="auditRootCause.length > 1"
            class="customBtn"
            type="danger"
            icon="fa fa-minus"
            @click="removeForm(index)"
          />
        </div>

        <div v-if="!loading">
          <DxButton
            v-if="paramsRoute.condition == 'add' && auditRootCause.length < 10"
            class="customBtn"
            text="Form"
            type="default"
            icon="fa fa-plus"
            @click="addForm"
          />

          <DxButton
            class="customBtn"
            text="Simpan Data"
            type="success"
            icon="save"
            :use-submit-behavior="true"
          />
        </div>

        <DxLoadIndicator v-else />
      </form>
    </div>
  </div>
</template>
            
  <script>
import { DxForm, DxSimpleItem, DxGroupItem } from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { mapGetters, mapActions } from "vuex";
import notify from "devextreme/ui/notify";
import axios from "axios";
import router from "@/router";
import DxValidationGroup from "devextreme-vue/validation-group";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";

export default {
  data() {
    return {
      loading: false,
      readOnly: false,
      auditRootCause: [
        {
          report_id: "",
          question: "Mengapa",
          answer: "Karena",
        },
      ],
      example: [
        {
          why: "Metode 5 Why : Mengapa posisi armada parkir menghadap ke jalan dengan kondisi full barang tanpa di gembok (CONTOH)",
          analys:
            "Analisa Akar Masalah : Karena yang parkir armada adalah tim procesing yang tidak mengetahui armada dalam kondisi ful muatan dan gembok armada hilang (CONTOH)",
        },
        {
          why: "Metode 5 Why : Mengapa tim procesing tidak mengetahui kondisi muatan armada serta gembok yang hilang (CONTOH)",
          analys:
            "Analisa Akar Masalah : Karena tim driver tidak melakukan serah terima dengan koordinator (CONTOH)",
        },
      ],
      paramsRoute: this.$route.params,
      auditGroupFinding: [],
    };
  },
  computed: {
    ...mapGetters("auditRootCause", [
      "getAuditRootCause",
      "getAuditRootCauseStatus",
    ]),
    ...mapGetters("auditReport", ["getAuditReport"]),
    ...mapGetters("auditGroupFinding", ["getAuditGroupFinding"]),
  },
  mounted() {
    if (
      this.paramsRoute.condition == "edit" &&
      this.paramsRoute.id.trim() !== ""
    ) {
      if (this.getAuditRootCause) {
        this.auditRootCause[0] = this.getAuditRootCause;
      } else {
        this.loadAuditRootCause(this.paramsRoute.id);
      }
      if (this.getAuditGroupFinding) {
        this.auditGroupFinding = this.getAuditGroupFinding;
      }
    } else {
      if (this.getAuditGroupFinding) {
        this.auditGroupFinding = this.getAuditGroupFinding;
      } else {
        this.loadAuditGroupFinding(this.paramsRoute.id);
      }
    }
  },
  emits: ["get-audit-root-cause"],
  methods: {
    async loadAuditGroupFinding(params) {
      try {
        const getGroupFinding = await this.$store.dispatch(
          "auditGroupFinding/getAuditGroupFinding",
          `?audit_report_id=${params}`
        );

        this.auditGroupFinding = getGroupFinding.data;
      } catch (error) {
        notify(error, "error", 2000);
      }
    },
    async loadAuditRootCause(params) {
      try {
        const getAuditRootCause = await this.$store.dispatch(
          "auditRootCause/getAuditRootCause",
          `/${params}`
        );

        this.auditRootCause[0] = getAuditRootCause.data;
        this.loadAuditGroupFinding(getAuditRootCause.data.report_id);
      } catch (error) {
        notify(error, "error", 2000);
      }
    },
    addForm() {
      this.auditRootCause.push({
        question: "Mengapa",
        answer: "Karena",
      });
    },
    removeForm(index) {
      this.auditRootCause.splice(index, 1);
    },
    async handleSubmit(e) {
      this.loading = true;

      this.$emit("get-audit-root-cause", this.auditRootCause);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    DxValidationGroup,
    DxLoadIndicator,
  },
};
</script>
            
            <style scoped>
.customBtn {
  margin: 1%;
}
.custom-form {
  margin-top: 10px;
}
.input-group {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(150px, 1fr)
  ); /* Auto-fit columns with a minimum width */
  gap: 10px; /* Optional: space between input elements */
}

.input-group .input-field {
  width: 100%;
}
</style>