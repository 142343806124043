<template>
  <div>
    <DxTabPanel>
      <DxItem title="Penilaian Pengendalian Risiko" template="risk-control" />
      <template #risk-control>
        <div class="dx-card" v-if="!loading">
          <table>
            <tr v-for="data in dataMatrix.score_control" :key="data.id">
              <th width="100" v-if="checkPermission">
                <DxButton
                  type="default"
                  icon="edit"
                  hint="edit"
                  @click="editRiskControl(data)"
                  v-if="permissionProps.canUpdate"
                />
                <DxButton
                  type="default"
                  hint="hapus"
                  icon="trash"
                  @click="popupDestroy(data, 'control')"
                  v-if="permissionProps.canDelete"
                />
              </th>
              <th width="30%">{{ data.desc_risk_control }}</th>
              <th width="5px">:</th>
              <th>{{ data.score_indicator }}</th>
              <th>{{ data.name_score_indicator }}</th>
            </tr>
            <tr>
              <th v-if="checkPermission"></th>
              <th>Nilai Control</th>
              <th width="5px">:</th>
              <th>{{ dataMatrix.total_control }}</th>
              <th>%</th>
            </tr>
          </table>
        </div>
        <DxLoadIndicator style="margin: 1%" v-else />
        <div>
          <DxButton
            v-if="permissionProps.canCreate"
            class="content-block"
            text="Penilaian Pengendalian"
            type="default"
            icon="fa fa-square-plus"
            hint="score"
            @click="addScoreControl"
          />
        </div>
      </template>
      <DxItem
        title="Detail Pengendalian Risiko"
        template="risk-control-detail"
      />
      <template #risk-control-detail>
        <div class="dx-card" v-for="data in riskControlDetail" :key="data.id">
          <table>
            <tr>
              <th width="100" v-if="checkPermission">
                <DxButton
                  type="default"
                  icon="edit"
                  hint="edit"
                  @click="editControlDetail(data)"
                  v-if="permissionProps.canUpdate"
                />
                <DxButton
                  type="default"
                  hint="hapus"
                  icon="trash"
                  @click="popupDestroy(data, 'detail')"
                  v-if="permissionProps.canDelete"
                />
              </th>
              <th width="200">{{ data.control_description }}</th>
              <th width="10">:</th>
              <td>{{ data.description }}</td>
            </tr>
          </table>
        </div>
        <DxButton
          v-if="permissionProps.canCreate"
          class="content-block"
          text="Detail Pengendalian Risiko"
          type="default"
          icon="add"
          hint="Detail Pengendalian Risiko"
          @click="addRiskControlDetail"
        />
      </template>
      <DxItem title="Tingkat Keparahan Risiko" template="risk-severity" />
      <template #risk-severity>
        <div class="dx-card">
          <table>
            <tr v-for="data in dataMatrix.score_severity" :key="data.id">
              <th width="100" v-if="checkPermission">
                <DxButton
                  type="default"
                  icon="edit"
                  hint="edit"
                  @click="editRiskSeverity(data)"
                  v-if="permissionProps.canUpdate"
                />
                <DxButton
                  type="default"
                  hint="hapus"
                  icon="trash"
                  @click="popupDestroy(data, 'severity')"
                  v-if="permissionProps.canDelete"
                />
              </th>
              <th width="30%">{{ data.desc_risk_severities }}</th>
              <th width="5px">:</th>
              <th>{{ data.score_indicator }}</th>
              <th>{{ data.name_score_indicator }}</th>
            </tr>
            <tr>
              <th v-if="checkPermission"></th>
              <th>Total Nilai Severity</th>
              <th width="5px">:</th>
              <th>{{ dataMatrix.total_severity }}</th>
              <th>%</th>
            </tr>
          </table>
        </div>
        <div>
          <DxButton
            v-if="permissionProps.canCreate"
            class="content-block"
            text="Penilaian Tingkat Keparahan"
            type="default"
            icon="fa fa-square-plus"
            hint="score"
            @click="addScoreSeverity"
          />
        </div>
      </template>
      <DxItem title="Rencana Perbaikan" template="risk-imp-plan" />
      <template #risk-imp-plan>
        <div class="dx-card" v-for="data in riskImpPlan" :key="data.id">
          <table>
            <tr>
              <th width="300">{{ data.desc_item }}</th>
              <th width="10">:</th>
              <td>{{ data.description }}</td>
            </tr>
            <tr>
              <th>By Who</th>
              <th>:</th>
              <td>{{ data.improve_by }}</td>
            </tr>
            <tr>
              <th>By When</th>
              <th>:</th>
              <td>{{ formatDate(data.improve_at) }}</td>
            </tr>
            <tr v-if="checkPermission">
              <th>
                <DxButton
                  type="default"
                  icon="edit"
                  hint="edit"
                  @click="editImpPlan(data)"
                  v-if="permissionProps.canUpdate"
                />
                <DxButton
                  type="default"
                  hint="hapus"
                  icon="trash"
                  @click="popupDestroy(data, 'improv')"
                  v-if="permissionProps.canDelete"
                />
              </th>
            </tr>
          </table>
        </div>
        <DxButton
          v-if="permissionProps.canCreate"
          class="content-block"
          text="Rencana Perbaikan"
          type="default"
          icon="add"
          hint="Rencana Perbaikan"
          @click="addImpPlan"
        />
      </template>
    </DxTabPanel>
    <br />
    <div class="container-info" v-if="dataMatrix.matrix != null">
      <div
        :class="{
          LOW: dataMatrix.matrix == 'LOW',
          MEDIUM: dataMatrix.matrix == 'MEDIUM',
          HIGH: dataMatrix.matrix == 'HIGH',
          EXTREME: dataMatrix.matrix == 'EXTREME',
        }"
      >
        {{ dataMatrix.matrix }}
      </div>
    </div>
    <!-- popup delete -->
    <DxPopup
      :width="300"
      height="auto"
      :show-title="true"
      title="Konfirmasi"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      v-model:visible="popupDelete"
    >
      <template #content="{}">
        <div class="popup-property-details">
          <b>Apakah yakin hapus data ?</b>
          <div class="justify-center">
            <DxButtonx
              class="customBtn"
              text="ya"
              icon="check"
              type="success"
              @click="confirmDestroy()"
            />
            <DxButtonx
              class="customBtn"
              text="batal"
              icon="close"
              type="danger"
              @click="popupDelete = false"
            />
          </div>
        </div>
      </template>
    </DxPopup>
  </div>
</template>
  
  <script>
import { DxTabPanel, DxItem } from "devextreme-vue/tab-panel";
import DxButton from "devextreme-vue/button";
import DxLoadIndicator from "devextreme-vue/load-indicator";
import DxPopup from "devextreme-vue/popup";
import DxButtonx from "devextreme-vue/button";

import notify from "devextreme/ui/notify";
import router from "@/router";

export default {
  components: {
    DxTabPanel,
    DxItem,
    DxLoadIndicator,
    DxButton,
    DxPopup,
    DxButtonx,
  },
  data() {
    return {
      riskControl: [],
      riskControlDetail: [],
      riskSeverity: [],
      riskImpPlan: [],
      popupDelete: false,
      deleteData: {},
      typeDelete: "",
      dataMatrix: {},
      loading: false,
    };
  },
  props: {
    role: {
      type: String,
    },
    riskIdentification: {
      type: Object,
    },
    permissionProps: {
      type: Object,
    },
  },
  computed: {
    checkPermission() {
      if (this.permissionProps.canUpdate || this.permissionProps.canDelete) {
        return true;
      }
      return false;
    },
  },
  emits: ["delete-data"],
  mounted() {
    this.getRiskControlDetail();
    this.getRiskImpPlan();
    this.getScoreAndMatrix();
  },
  methods: {
    async getScoreAndMatrix() {
      this.loading = true;
      const text = `/getmatrix/${this.riskIdentification.id}`;
      const getMatrix = await this.$store.dispatch(
        "riskScore/getRiskScore",
        text
      );

      this.dataMatrix = getMatrix.data;
      this.loading = false;
    },
    destroyData(params) {
      const vThis = this;
      this.$store
        .dispatch(params, this.deleteData)
        .then((res) => {
          notify("delete success", "success", 2000);
          vThis.getRiskControlDetail();
          vThis.getRiskImpPlan();
          vThis.getScoreAndMatrix();
        })
        .catch((e) => {
          notify(e.message, "error", 2000);
        });
    },
    confirmDestroy() {
      if (this.typeDelete == "control" || this.typeDelete == "severity") {
        this.destroyData("riskScore/destroyRiskScore");
      } else if (this.typeDelete == "detail") {
        this.destroyData("riskControlDetail/destroyRiskControlDetail");
      } else if (this.typeDelete == "improv") {
        this.destroyData("riskImprovementPlan/destroyRiskImpPlan");
      } else {
        console.log("harus diisi");
      }
      this.popupDelete = false;
    },
    popupDestroy(params, type) {
      this.popupDelete = true;

      this.deleteData = params;
      this.typeDelete = type;
    },
    formatDate(params) {
      let date = new Date(params);
      const dd = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      const mounth = parseInt(date.getMonth()) + 1;
      const mm = mounth < 10 ? "0" + mounth : mounth;

      return dd + "/" + mm + "/" + date.getFullYear();
    },
    editControlDetail(params) {
      this.$store.dispatch("riskControlDetail/setRiskControlDetail", params);
      router.push(`/risk-control-detail/form/edit/${params.id}`);
    },
    addRiskControlDetail() {
      router.push(
        `/risk-control-detail/form/add/${this.riskIdentification.id}`
      );
    },
    editImpPlan(params) {
      this.$store.dispatch("riskImprovementPlan/setRiskImpPlan", params);
      router.push(`/risk-improv-plan/form/edit/${params.id}`);
    },
    addImpPlan() {
      router.push(`/risk-improv-plan/form/add/${this.riskIdentification.id}`);
    },
    async editRiskSeverity(params) {
      this.$store.dispatch("riskScore/setRiskScore", params);
      router.push(`/risk-severity-score/form/edit/${params.id}`);
    },
    addScoreSeverity() {
      // setlocalstorage
      let savedData = [];
      this.dataMatrix.score_severity.forEach((element) => {
        savedData.push(element.score_component_id);
      });
      localStorage.setItem("assessment", JSON.stringify(savedData));
      // end

      router.push(
        `/risk-severity-score/form/add/${this.riskIdentification.id}`
      );
    },
    async editRiskControl(params) {
      this.$store.dispatch("riskScore/setRiskScore", params);
      router.push(`/risk-control-score/form/edit/${params.id}`);
    },
    addScoreControl() {
      // setlocalstorage
      let savedData = [];
      this.dataMatrix.score_control.forEach((element) => {
        savedData.push(element.score_component_id);
      });
      localStorage.setItem("assessment", JSON.stringify(savedData));
      // end

      router.push(`/risk-control-score/form/add/${this.riskIdentification.id}`);
    },
    async getRiskControlDetail() {
      const text = `/control-item?identification=${this.riskIdentification.id}`;
      const controlDetail = await this.$store.dispatch(
        "riskControlDetail/getRiskControlDetail",
        text
      );

      this.riskControlDetail = controlDetail.data;
    },
    async getRiskImpPlan() {
      const text = `/control-item?identification=${this.riskIdentification.id}`;
      const riskImp = await this.$store.dispatch(
        "riskImprovementPlan/getRiskImpPlan",
        text
      );
      this.riskImpPlan = riskImp.data;
    },
  },
};
</script>;

<style scoped>
table tr th {
  padding: 10px;
}
.container-info {
  display: flex;
  justify-content: center; /* Memusatkan elemen secara horizontal */
  align-items: center; /* Memusatkan elemen secara vertikal */
}
.EXTREME {
  display: inline-block; /* Menyesuaikan lebar dengan konten */
  padding: 10px 20px; /* Memberikan padding agar teks tidak terlalu rapat */
  text-align: center;
  color: white;
  font-size: 30px;
  background-color: red;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}
.HIGH {
  display: inline-block;
  padding: 10px 20px;
  text-align: center;
  font-size: 30px;
  background-color: rgb(255, 187, 0);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}
.MEDIUM {
  display: inline-block;
  padding: 10px 20px;
  text-align: center;
  font-size: 30px;
  background-color: yellow;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}
.LOW {
  display: inline-block;
  padding: 10px 20px;
  text-align: center;
  font-size: 30px;
  background-color: rgb(121, 255, 109);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}
.popup-property-details {
  overflow: hidden;
  position: relative;
}
.justify-center {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.customBtn {
  margin: 1%;
}
</style>
