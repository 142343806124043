import axios from 'axios';
import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            result: [],
            detail: []
        }
    },
    mutations: {
        setData(state, payload) {
            state.result = payload
        },
        setDetail(state, payload) {
            state.detail = payload
        },
    },
    actions: {
        async getDocApprovalByDocFileId({ commit, state }, params) {
            console.log(params);
            return await Api().get(
                `doc-approvals/doc-file/${params.id}${params.paramsText}`
            ).then((res) => {
                // commit('setDetail', res.data)
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async getDocApprovals({ commit, state }, params) {
            return await Api()
                .get(`doc-approvals${params}`).then((res) => {
                    commit('setDetail', res.data)
                    return res.data
                }).catch((err) => {
                    console.log(err.message);
                });
        },
        async postDocApproval({ }, params) {
            return await Api()
                .post("doc-approvals", params,);
        },
        async postMultipleDocApproval({ }, params) {
            return await Api()
                .post("doc-approvals/multiple", params,);
        },
        async updateDocApproval({ }, params) {
            return await Api()
                .put(`doc-approvals/${params.id}`, params,);
        },
        async destroyDocApproval({ }, params) {
            return await Api()
                .delete(`doc-approvals/${params.id}`, params,);
        },
    },
}