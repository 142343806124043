<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <span class="master-detail-caption">Pencarian Item Verifikasi</span>
      <form action="/auditFindingItem" @submit.prevent="handleSubmit">
        <div class="input-group">
          <DxSelectBox
            class="group"
            :data-source="unitBisnisOptions"
            label="Pilih Unit Bisnis"
            value-expr="id"
            display-expr="name"
            @valueChanged="changeUnitBisnis"
            v-model="options.company"
          >
          </DxSelectBox>
          <DxSelectBox
            class="group"
            :data-source="departmentOptions"
            label="Pilih Department"
            value-expr="department_id"
            display-expr="department_name"
            @valueChanged="changeDepartment"
            v-model="options.department"
            :read-only="readOnlyDept"
          >
          </DxSelectBox>
        </div>

        <div>
          <DxSelectBox
            v-if="!itemEnable"
            :data-source="verificationOptions"
            label="Pilih Verifikasi Item *"
            value-expr="id"
            display-expr="verification_items"
            v-model="auditFinding.verification"
            :read-only="readOnlyVerf"
            :search-enabled="true"
            @valueChanged="changeVerificationItem"
            item-template="item"
          >
            <template #item="{ data: item, index }">
              <div class="custom-item">
                <div class="product-name">
                  <b>{{ index + 1 }}. {{ item.verification_items }}</b>
                  <span>
                    / Proses :
                    {{
                      item.risk_control.potential.pic_checklist.activity.process
                        .process_name
                    }}</span
                  >
                  <span>
                    / Clausul :
                    {{
                      item.risk_control.potential.pic_checklist.activity.process
                        .checklist.clausul_relevan
                    }}
                  </span>
                </div>
              </div>
            </template>
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxSelectBox>
          <div style="margin-top: 5px" v-if="checklistHide">
            <a @click.prevent="popupChecklist = true" href="#"
              ><i class="fa-solid fa-circle-info"></i> Detail Checklist</a
            >
          </div>
          <div style="margin-top: 5px; text-align: right" v-if="!resetHide">
            <a @click.prevent="popupResetItem = true" href="#"
              ><i class="fa-solid fa-trash"></i> Reset Verifikasi Item</a
            >
          </div>
        </div>

        <DxTextBox
          v-if="itemEnable"
          class="custom-form"
          label="Verifikasi Item *"
          v-model:value="auditFinding.item"
          :read-only="true"
        >
        </DxTextBox>
      </form>
    </div>
    <div class="content-block dx-card responsive-paddings">
      <form action="/addAuditFinding" @submit.prevent="handleSubmit">
        <DxTextArea
          class="custom-form"
          label="Catatan Hasil Verifikasi *"
          :height="90"
          :input-attr="{ 'aria-label': 'Notes' }"
          v-model:value="auditFinding.verification_desc"
        >
          <DxValidator>
            <DxRequiredRule message="Required" />
          </DxValidator>
        </DxTextArea>

        <div class="input-group">
          <DxSelectBox
            class="group"
            :data-source="verificationStatusOptions"
            label="Pilih Status Verifikasi *"
            value-expr="name"
            display-expr="name"
            :is-required="true"
            v-model="auditFinding.verification_status"
          >
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxSelectBox>
          <DxTextBox
            class="group"
            label="Auditee"
            v-model:value="auditFinding.auditee"
          >
          </DxTextBox>
          <DxTextBox
            class="group"
            label="Auditor"
            v-model:value="auditFinding.auditor"
          >
          </DxTextBox>
        </div>

        <DxButton
          v-if="!loading"
          text="Simpan Data"
          type="success"
          icon="save"
          :use-submit-behavior="true"
        />
        <DxLoadIndicator v-else />
      </form>
    </div>

    <!-- popup detail -->
    <DxPopup
      :width="popupWidth"
      :height="popupHeight"
      :show-title="true"
      title="Detail Checklist Audit"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      :show-close-button="true"
      v-model:visible="popupChecklist"
    >
      <template #content="{}">
        <DxScrollView width="100%" height="100%">
          <div class="popup-property-details">
            <div class="dx-fieldset">
              <div class="dx-card responsive-paddings">
                <div class="dx-field">
                  <div class="dx-field-label">Clausul Relevan</div>
                  <div class="dx-field-value">
                    : {{ auditChecklist.clausul_relevan }}
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Proses</div>
                  <div class="dx-field-value">
                    : {{ auditChecklist.process_name }}
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Lingkup Aktivitas</div>
                  <div class="dx-field-value">
                    : {{ auditChecklist.activity }}
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">PIC</div>
                  <div class="dx-field-value">
                    :
                    {{ auditChecklist.pic }}
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Potensi Risiko</div>
                  <div class="dx-field-value">
                    :
                    {{ auditChecklist.potential }}
                  </div>
                </div>

                <div class="dx-field">
                  <div class="dx-field-label">Tingkat Risiko</div>
                  <div class="dx-field-value">
                    :
                    {{ auditChecklist.risk_level }}
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Pengendalian Risiko</div>
                  <div class="dx-field-value">
                    :
                    {{ auditChecklist.risk_control_name }}
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">Tipe Pengendalian Risiko</div>
                  <div class="dx-field-value">
                    :
                    {{ auditChecklist.type }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DxScrollView>
      </template>
    </DxPopup>

    <!-- popup reset verification item -->
    <DxPopup
      :width="300"
      height="auto"
      :show-title="true"
      title="Konfirmasi"
      :drag-enabled="false"
      :show-close-button="true"
      v-model:visible="popupResetItem"
    >
      <template #content="{}">
        <div class="popup-property-details">
          <b>Apakah yakin reset verifikasi item ?</b>
          <div class="justify-center">
            <DxButton
              class="customBtn"
              text="ya"
              icon="check"
              type="success"
              @click="resetVerification"
            />
          </div>
        </div>
      </template>
    </DxPopup>
  </div>
</template>
            
  <script>
import { DxForm, DxSimpleItem, DxGroupItem } from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { mapGetters, mapActions } from "vuex";
import notify from "devextreme/ui/notify";
import axios from "axios";
import router from "@/router";
import DxValidationGroup from "devextreme-vue/validation-group";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";
import DxPopup from "devextreme-vue/popup";
import { DxScrollView } from "devextreme-vue/scroll-view";

export default {
  data() {
    return {
      loading: false,
      auditFinding: {},
      readOnly: false,
      verificationStatusOptions: [
        { name: "OK" },
        { name: "NC" },
        { name: "Obs" },
      ],
      unitBisnisOptions: "",
      departmentOptions: "",
      verificationOptions: "",
      auditChecklist: {},
      readOnlyCheck: true,
      readOnlyDept: true,
      readOnlyVerf: true,
      checklistHide: false,
      options: {
        company: null,
        department: null,
      },
      itemEnable: false,
      paramId: this.$route.params.id,
      popupWidth: "60%", // width responsif
      popupHeight: "90%", // high otomatis
      popupChecklist: false,
      popupResetItem: false,
      resetHide: false,
    };
  },
  computed: {
    ...mapGetters("auditFinding", ["getAuditFinding", "getAuditFindingStatus"]),
    ...mapGetters("auditVerificationItem", ["getAuditVerificationItem"]),
    ...mapGetters("department", ["getAllDepartment"]),
    ...mapGetters("unitBisnis", ["getAllUnitBisnis"]),
  },
  mounted() {
    if (this.paramId && this.paramId.trim() !== "") {
      this.itemEnable = true;
      this.resetHide = true;
      if (this.getAuditFinding) {
        this.auditFinding = this.getAuditFinding;
        this.auditFinding.item =
          this.getAuditFinding.verification_item.verification_items;
      } else {
        this.loadFinding(this.paramId);
      }
    }
    this.unitBisnisOptions = this.getAllUnitBisnis;
    this.relatedDepartments = this.getAllDepartment;
    // start responsive popup detail
    window.addEventListener("resize", this.updatePopupSize);
    this.updatePopupSize();
    // end
  },
  beforeDestroy() {
    // start responsive popup detail
    window.removeEventListener("resize", this.updatePopupSize);
    // end
  },
  props: {},
  emits: ["get-audit-finding"],
  methods: {
    resetVerification() {
      localStorage.removeItem("selectedItems");
      this.popupResetItem = false;
      notify("Reset verifikasi berhasil", "success", 2000);
    },
    updatePopupSize() {
      this.popupWidth = window.innerWidth <= 600 ? "95%" : "60%";
    },
    async loadFinding(params) {
      try {
        const getFinding = await this.$store.dispatch(
          "auditFinding/getAuditFinding",
          `/${params}`
        );
        this.auditFinding = getFinding.data;
        this.auditFinding.item =
          getFinding.data.verification_item.verification_items;
      } catch (error) {
        notify(error, "error", 2000);
      }
    },
    async changeVerificationItem(params) {
      if (params.value) {
        const text = `/checklist/${params.value}`;
        const getChecklistJoin = await this.$store.dispatch(
          "auditVerificationItem/getAuditVerificationItem",
          text
        );
        this.checklistHide = true;

        this.auditChecklist = getChecklistJoin.data;
      } else {
        return;
      }
    },
    async changeDepartment(params) {
      if (params.value) {
        const savedData =
          JSON.parse(localStorage.getItem("selectedItems")) || [];

        const text = `?department=${params.value}&limit=999999`;
        this.$store
          .dispatch("auditVerificationItem/getAuditVerificationItem", text)
          .then((res) => {
            this.verificationOptions = res.data.filter(
              (item) => !savedData.includes(item.id)
            );
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
        this.readOnlyVerf = false;
        this.checklistHide = false;
        this.itemEnable = false;
        if (!this.paramId) {
          this.auditFinding.verification = null;
        }
        this.resetHide = true;
      }
    },
    async changeUnitBisnis(params) {
      const depart = this.getAllDepartment?.filter(
        (e) => e.company_id == params.value
      );

      this.readOnlyDept = false;
      this.checklistHide = false;
      if (!this.paramId) {
        this.readOnlyVerf = true;
        this.options.department = null;
        this.auditFinding.verification = null;
      }
      this.departmentOptions = depart;
    },
    async handleSubmit(e) {
      this.loading = true;
      if (!this.paramId) {
        this.auditFinding.company_id = this.auditChecklist.company_id;
        this.auditFinding.department_id = this.auditChecklist.department_id;
        this.auditFinding.section_id = this.auditChecklist.section_id;
        this.auditFinding.subsection_id = this.auditChecklist.subsection_id;
        this.auditFinding.audit_checklist_id = this.auditChecklist.checklist_id;
        if (this.auditFinding.verification_status == "OK") {
          this.auditFinding.status = true;
        }
      }
      if (this.auditFinding.verification) {
        this.auditFinding.verification_item_id = this.auditFinding.verification;
      }
      this.$emit("get-audit-finding", this.auditFinding);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    DxValidationGroup,
    DxLoadIndicator,
    DxPopup,
    DxScrollView,
  },
};
</script>
            
            <style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
.dx-list-item-content {
  white-space: normal;
}
.notification {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  text-align: center;
  color: white;
  font-weight: bold;
  padding-right: 5px;
}
.Ngreen {
  background-color: green;
}
.Nyellow {
  background-color: orange;
}
.group {
  display: inline-block;
}
.input-group {
  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(150px, 1fr)
  ); /* Auto-fit columns with a minimum width */
  gap: 10px; /* Optional: space between input elements */
  margin-bottom: 25px;
  margin-top: 10px;
}

.input-group .input-field {
  width: 100%;
}
.popup-property-details {
  overflow: hidden;
  position: relative;
}
.justify-center {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.customBtn {
  margin: 1%;
}
</style>