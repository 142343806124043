import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            riskScore: null,
            riskScoreStatus: null,
        }
    },
    mutations: {
        setRiskScore(state, payload) {
            state.riskScore = payload
        },
        setRiskScoreStatus(state, payload) {
            state.riskScoreStatus = payload
        },
    },
    actions: {
        setRiskScore({ commit }, params) {
            commit('setRiskScore', params)
        },
        setRiskScoreStatus({ commit }, params) {
            commit('setRiskScoreStatus', params)
        },
        async getRiskScore({ commit, state }, params) {
            return await Api().get(
                `risk-scores${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postRiskScore({ commit, state }, params) {
            return await Api().post(
                `risk-scores`, params
            ).then((res) => {
                return res.data
            })
        },
        async postMultipleRiskScore({ commit, state }, params) {
            return await Api().post(
                `/risk-scores/multiple`, params
            ).then((res) => {
                return res.data
            })
        },
        async editRiskScore({ commit, state }, params) {
            return await Api().put(
                `risk-scores/${params.id}`, params
            ).then((res) => {
                return res.data
            })
        },
        async destroyRiskScore({ commit, state }, params) {
            return await Api().delete(
                `risk-scores/${params.id}`
            ).then((res) => {
                return res.data
            })
        }
    },
    getters: {
        getRiskScore(state) {
            return state.riskScore;
        },
        getRiskScoreStatus(state) {
            return state.riskScoreStatus;
        },
    }
}