import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            auditPlan: null,
            auditPlanStatus: null,
        }
    },
    mutations: {
        setAuditPlan(state, payload) {
            state.auditPlan = payload
        },
        setAuditPlanStatus(state, payload) {
            state.auditPlanStatus = payload
        },
    },
    actions: {
        setAuditPlan({ commit }, params) {
            commit('setAuditPlan', params)
        },
        setAuditPlanStatus({ commit }, params) {
            commit('setAuditPlanStatus', params)
        },
        async getAuditPlan({ commit, state }, params) {
            return await Api().get(
                `audit-plans${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postAuditPlan({ commit, state }, params) {
            return await Api().post(
                `audit-plans`, params
            ).then((res) => {
                return res.data
            })
        },
        async postMultipleAuditPlan({ commit, state }, params) {
            return await Api().post(
                `audit-plans/multiple`, params
            ).then((res) => {
                return res.data
            })
        },
        async editAuditPlan({ commit, state }, params) {
            return await Api().put(
                `audit-plans/${params.id}`, params
            ).then((res) => {
                return res.data
            })
        },
        async destroyAuditPlan({ commit, state }, params) {
            return await Api().delete(
                `audit-plans/${params.id}`
            ).then((res) => {
                return res.data
            })
        }
    },
    getters: {
        getAuditPlan(state) {
            return state.auditPlan;
        },
        getAuditPlanStatus(state) {
            return state.auditPlanStatus;
        },
    }
}