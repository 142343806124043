<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <form action="/addRiskControl" @submit.prevent="handleSubmit">
        <div v-for="(form, index) in riskControlDetail" :key="index">
          <DxSelectBox
            class="custom-form"
            :data-source="itemOptions"
            label="Pilih Pengendalian Risiko"
            value-expr="id"
            display-expr="description"
            v-model="form.control_id"
            :is-required="true"
          >
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxSelectBox>
          <DxTextArea
            class="custom-form"
            label="Deskripsi"
            :height="90"
            :input-attr="{ 'aria-label': 'Notes' }"
            v-model:value="form.description"
          >
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxTextArea>
          <DxButton
            v-if="riskControlDetail.length > 1"
            class="customBtn"
            type="danger"
            icon="fa fa-minus"
            @click="removeForm(index)"
          />
        </div>

        <div v-if="!loading">
          <DxButton
            v-if="
              paramsRoute.condition == 'add' && riskControlDetail.length < 10
            "
            class="customBtn"
            type="default"
            icon="fa fa-plus"
            @click="addForm"
          />
          <DxButton
            text="Simpan Data"
            type="success"
            icon="save"
            :use-submit-behavior="true"
          />
        </div>
        <DxLoadIndicator v-else />
      </form>
    </div>
  </div>
</template>
              
        <script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxEmailRule,
} from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { mapGetters, mapActions } from "vuex";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";

import notify from "devextreme/ui/notify";
import FilterTableUnitBisnis from "@/components/partials/FilterTableUnitBisnis.vue";

export default {
  data() {
    return {
      loading: false,
      riskControlDetail: [
        {
          control_id: "",
          description: "",
        },
      ],
      itemOptions: [],
      paramsRoute: this.$route.params,
    };
  },
  mounted() {
    if (
      this.paramsRoute.condition == "edit" &&
      this.paramsRoute.id.trim() !== ""
    ) {
      if (this.getRiskControlDetail) {
        this.riskControlDetail[0] = this.getRiskControlDetail;
      } else {
        this.loadRiskDetail(this.paramsRoute.id);
      }
    }
    this.getRiskControl();
  },
  emits: ["get-risk-control-detail"],
  computed: {
    ...mapGetters("authentication", ["getUserDetail"]),
    ...mapGetters("riskControlDetail", [
      "getRiskControlDetail",
      "getRiskControlDetailStatus",
    ]),
  },
  methods: {
    async loadRiskDetail(params) {
      try {
        const riskDetail = await this.$store.dispatch(
          "riskControlDetail/getRiskControlDetail",
          `/${params}`
        );

        this.riskControlDetail[0] = riskDetail.data;
      } catch (error) {
        notify(error, "error", 2000);
      }
    },
    addForm() {
      this.riskControlDetail.push({
        control_id: "",
        description: "",
      });
    },
    removeForm(index) {
      this.riskControlDetail.splice(index, 1);
    },
    async getRiskControl() {
      const text = `/control-item?limit=99999`;
      const dataControl = await this.$store.dispatch(
        "riskControl/getRiskControl",
        text
      );
      this.itemOptions = dataControl.data;
    },
    handleSubmit(e) {
      this.loading = true;
      this.$emit("get-risk-control-detail", this.riskControlDetail);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxEmailRule,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    FilterTableUnitBisnis,
    DxLoadIndicator,
  },
};
</script>
        
        <style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
.customBtn {
  margin: 1%;
}
</style>