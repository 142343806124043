<template>
  <div>
    <h2 class="content-block">
      {{ title }}
    </h2>

    <filter-table-unit-bisnis
      @get-unit-bisnis="getCompany"
      @get-department="getDepartment"
      v-if="permissions.unfilteredData"
    ></filter-table-unit-bisnis>

    <!-- <div
      class="dx-card"
      style="justify-content: center; padding: 10px"
      v-if="!verifiedRegulation"
    >
      <div style="justify-content: center; display: flex">
        <b>Filter Status Regulasi</b>
      </div>
      <div style="justify-content: center; display: flex">
        <DxRadioGroup
          :items="statusFilterOptions"
          value-expr="key"
          display-expr="name"
          v-model:value="valueStatus"
          layout="horizontal"
          @valueChanged="handleRefresh()"
        >
        </DxRadioGroup>
      </div>
    </div> -->

    <br />

    <dx-data-grid
      class="dx-card wide-card"
      :data-source="dataSource"
      :show-borders="false"
      :column-auto-width="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :remote-operations="true"
      :search-value="true"
      :word-wrap-enabled="true"
      :ref="gridRef"
      @toolbar-preparing="onToolbarPreparing($event)"
      @exporting="onExporting"
    >
      <dx-paging :page-size="10" />
      <dx-pager
        :show-page-size-selector="true"
        :show-info="true"
        :allowed-page-sizes="pageSizes"
        :showNavigationButtons="true"
        infoText="Hal {0} dari {1} ({2} items)"
        :visible="true"
      />
      <DxSearchPanel :visible="true" />

      <dx-column type="buttons" :width="110" data-field="Action">
        <DxButton
          hint="details"
          icon="fa fa-share-from-square"
          @click="details"
        />
        <DxButton
          hint="edit"
          icon="fa fa-pen-to-square"
          @click="edit"
          v-if="permissions.canUpdate"
        />
        <DxButton
          hint="hapus"
          icon="fa fa-trash"
          @click="popupDestroy"
          v-if="permissions.canDelete"
        />
      </dx-column>
      <dx-column data-field="regulation_number" caption="Nomor Regulasi" />
      <dx-column data-field="type_regulation" caption="Jenis Peraturan" />
      <dx-column
        data-field="status"
        caption="Status"
        cell-template="grid-cell"
        width="100"
        :allow-sorting="false"
      />
      <template #grid-cell="{ data }">
        <div
          :class="{
            done: data.value == 'done',
            process: data.value == 'process',
          }"
        >
          {{ data.value }}
        </div>
      </template>
      <dx-column data-field="regulation_topic" caption="Topik Regulator" />

      <DxMasterDetail :enabled="true" template="masterDetailTemplate" />
      <template #masterDetailTemplate="{ data: regulation }">
        <table-regulation-doc
          :regulationListProps="regulation.data"
          :manageRegulationProps="manageRegulation"
          :permissionProps="permissions"
        />
      </template>
    </dx-data-grid>

    <!-- popup Delete -->
    <DxPopup
      :width="300"
      height="auto"
      :show-title="true"
      title="Konfirmasi"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      v-model:visible="popupDelete"
    >
      <template #content="{}">
        <div class="popup-property-details">
          <b>Apakah yakin hapus data ?</b>
          <div class="justify-center">
            <DxButtonx
              class="customBtn"
              text="ya"
              icon="check"
              type="success"
              @click="confirmDestroy()"
            />
            <DxButtonx
              class="customBtn"
              text="batal"
              icon="close"
              type="danger"
              @click="popupDelete = false"
            />
          </div>
        </div>
      </template>
    </DxPopup>
  </div>
</template>
  
  <script>
import tableRegulationDoc from "./tableRegulationDocument.vue";
import DxDataGrid, {
  DxColumn,
  DxFilterRow,
  DxLookup,
  DxPager,
  DxPaging,
  DxEditing,
  DxForm,
  DxSearchPanel,
  DxButton,
  DxMasterDetail,
  DxSelection,
} from "devextreme-vue/data-grid";
// Our demo infrastructure requires us to use 'file-saver-es'.
// We recommend that you use the official 'file-saver' package in your applications.
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";

import { DxTextArea } from "devextreme-vue/text-area";
import { DxItem } from "devextreme-vue/form";
import { DxTextBox } from "devextreme-vue/text-box";
import DxPopup from "devextreme-vue/popup";
import DxButtonx from "devextreme-vue/button";

import CustomStore from "devextreme/data/custom_store";
import router from "@/router";
import "whatwg-fetch";
import notify from "devextreme/ui/notify";
import { mapGetters, mapActions } from "vuex";
import DxRadioGroup from "devextreme-vue/radio-group";

import FilterTableUnitBisnis from "@/components/partials/FilterTableUnitBisnis.vue";

const isNotEmpty = (value) =>
  value !== undefined && value !== null && value !== "";

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxLookup,
    DxPager,
    DxPaging,
    DxEditing,
    DxPopup,
    DxForm,
    DxItem,
    DxTextArea,
    DxSearchPanel,
    DxButton,
    DxTextBox,
    FilterTableUnitBisnis,
    DxMasterDetail,
    tableRegulationDoc,
    Workbook,
    saveAs,
    DxSelection,
    DxButtonx,
    DxRadioGroup,
  },
  data() {
    const vthis = this;
    const dataSource = {
      store: new CustomStore({
        key: "id",
        load: async function (loadOptions) {
          var sortedBy = "";
          var sort = "";
          let filterValue = "";
          if (loadOptions.sort) {
            sortedBy += loadOptions.sort[0].selector;
            if (loadOptions.sort[0].desc) {
              sort += "desc";
            } else {
              sort += "asc";
            }
          }

          if (loadOptions.filter) {
            filterValue = loadOptions.filter[0].filterValue;
          }

          var perPage = loadOptions.take;
          var offset = loadOptions.skip;
          var page = offset / perPage + 1;

          var datax = vthis.fetch({
            limit: perPage || 10,
            page: page || 1,
            sorted_by: sortedBy || "updated_at",
            sort: sort || "desc",
            search: filterValue,
            department: vthis.department || "",
          });

          return await datax;
        },
      }),
    };
    return {
      gridRef: "regulation-list-table",
      dataSource: dataSource,
      pageSizes: [5, 10, 15],
      unitBisnis: "",
      department: "",
      departmentTitle: "",
      btnAdd: null,
      title: "",
      popupDelete: false,
      deleteRegulation: {},
      permissions: {
        canCreate: null,
        canUpdate: null,
        canDelete: null,
        canVerified: null,
        unfilteredData: null,
      },
      statusFilterOptions: [
        { key: "process,done", name: "All" },
        { key: "process", name: "Proses" },
        { key: "done", name: "Done" },
      ],
      valueStatus: "process,done",
    };
  },
  created() {
    const permission = this.$route.meta.permission;
    // check permission
    this.permissions.canCreate = (permission & 2) > 0;
    this.permissions.canUpdate = (permission & 4) > 0;
    this.permissions.canVerified = (permission & 8) > 0;
    this.permissions.canDelete = (permission & 16) > 0;
    this.permissions.unfilteredData = (permission & 32) > 0;
  },
  props: {
    manageRegulation: {
      type: Boolean,
      default: false,
    },
    verifiedRegulation: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.department = "";
  },
  computed: {
    dataGrid: function () {
      return this.$refs[this.gridRef].instance;
    },

    ...mapGetters("regulationList", ["getRegulationList"]),
    ...mapGetters("authentication", ["getUserDetail"]),
    ...mapGetters("department", ["getAllDepartment"]),
  },
  methods: {
    handleRefresh(params) {
      this.dataGrid.refresh();
    },
    departmentName(cellInfo) {
      const depart = this.getAllDepartment.find(
        (res) => res.department_id == cellInfo.value
      );
      if (!depart) {
        return "-";
      }
      return `${depart.company_name} - ${depart.department_name}`;
    },
    async getExport() {
      const dataExport = await this.$store.dispatch(
        "regulationList/getRegulationLists",
        "/export"
      );
      return await dataExport.data;
    },
    async onExporting(e) {
      const data = await this.getExport();

      if (data.length < 1) {
        notify("data tidak ditemukan", "warning", 2000);
        this.loading = false;
        return;
      }

      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Regulation_list");
      const columns = [
        {
          header: "Department",
          key: "department_id",
          transformer: (id) => this.departmentName({ value: id }),
        },
        {
          header: "No Peraturan dan Judul",
          key: "regulation_number",
        },
        {
          header: "Persentase Regulasi",
          key: "persentase_detail",
          style: { numFmt: "0%" },
        },
        { header: "Bab", key: "chapter" },
        { header: "Pasal", key: "article" },
        { header: "Ayat", key: "verse" },
        { header: "Inti Sari", key: "summary" },
        { header: "Bukti Yang Harus Dipenuhi", key: "total_impl" },
        { header: "SUDAH", key: "done" },
        { header: "PROSES", key: "process" },
        { header: "DITOLAK", key: "reject" },
        { header: "Bukti Yang Diunggah", key: "actual_impl" },
        {
          header: "Persentase Pemenuhan",
          key: "percentage",
          style: { numFmt: "0%" },
        },
      ];

      // Tambahkan header
      worksheet.columns = columns.map((col) => ({
        header: col.header,
        key: col.key,
        width: 20, // Lebar kolom default
      }));

      // Terapkan gaya untuk header
      const headerRow = worksheet.getRow(1); // Baris pertama sebagai header
      headerRow.eachCell((cell) => {
        cell.font = {
          bold: true, // Ubah font menjadi bold
        };
        cell.alignment = {
          horizontal: "center", // Teks rata tengah secara horizontal
          vertical: "middle", // Teks rata tengah secara vertikal
        };
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
        cell.fill = {
          type: "pattern", // Jenis pengisian pola
          pattern: "solid", // Pola solid
          fgColor: { argb: "D3D3D3" }, // Warna abu-abu soft (hex code: #D3D3D3)
        };
      });

      data.forEach((item) => {
        // Hitung jumlah total audit_group_finding untuk menambahkan garis
        const totalFindings = item.details?.length || 1; // Minimal 1 baris untuk data utama

        // Baris awal untuk data ini menambahkan garis
        const startRow = worksheet.lastRow ? worksheet.lastRow.number + 1 : 1;

        // Tambahkan data utama di baris pertama
        const mainRowData = {};
        columns.forEach(({ key, transformer }) => {
          mainRowData[key] = transformer ? transformer(item[key]) : item[key];
        });

        // Tambahkan `Details` ke baris utama jika ada
        mainRowData["persentase_detail"] =
          item.done_count / item.details?.length || 0;

        mainRowData["chapter"] = item.details?.[0]?.chapter || "-";
        mainRowData["article"] = item.details?.[0]?.article || "-";
        mainRowData["verse"] = item.details?.[0]?.verse || "-";
        mainRowData["summary"] = item.details?.[0]?.summary || "-";
        mainRowData["total_impl"] = item.details?.[0]?.total_impl || 0;
        mainRowData["done"] = item.details?.[0]?.impl_done || 0;
        mainRowData["process"] = item.details?.[0]?.impl_waiting || 0;
        mainRowData["reject"] = item.details?.[0]?.impl_reject || 0;
        mainRowData["actual_impl"] = item.details?.[0]?.actual_impl || 0;

        const percentage =
          item.details?.[0]?.total_impl > 0
            ? item.details?.[0]?.impl_done / item.details?.[0]?.total_impl
            : 0;
        mainRowData["percentage"] = percentage;

        const mainRow = worksheet.addRow(mainRowData);

        // Mengatur format persentase untuk kolom yang relevan
        const percentageDetailCell = mainRow.getCell("persentase_detail");
        percentageDetailCell.numFmt = "0%";

        // Tambahkan data detail untuk `Temuan B` dan seterusnya
        if (item.details?.length > 1) {
          item.details.slice(1).forEach((detail) => {
            const detailRowData = {
              chapter: detail.chapter || "-",
              article: detail.article || "-",
              verse: detail.verse || "-",
              summary: detail.summary || "-",
              total_impl: detail.total_impl || 0,
              done: detail.impl_done || 0,
              process: detail.impl_waiting || 0,
              reject: detail.impl_reject || 0,
              actual_impl: detail.actual_impl || 0,
              percentage: detail.impl_done / detail.total_impl || 0,
            };
            // format % column percentage
            const percentageRow = worksheet.addRow(detailRowData);
            const percentageCell = percentageRow.getCell("percentage");
            percentageCell.numFmt = "0%";
          });
        }
        // const test =
        const percentageCell = mainRow.getCell("percentage");
        percentageCell.numFmt = "0%";

        // Tambahkan border pada setiap cell dari startRow ke totalFindings
        for (
          let rowIndex = startRow;
          rowIndex < startRow + totalFindings;
          rowIndex++
        ) {
          const row = worksheet.getRow(rowIndex);
          columns.forEach(({ key }) => {
            const cell = row.getCell(key);
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
          });
        }
      });

      // export file excle
      workbook.xlsx
        .writeBuffer()
        .then((buffer) => {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            `Laporan-Regulation-${Date.now()}.xlsx`
          );
          // close popup
          this.popupExport = false;
        })
        .catch((e) => {
          console.log(e, "error");
          notify(e.message, "error", 2000);
        });
    },
    getCompany(value) {
      this.unitBisnis = value;
    },
    getDepartment(value) {
      this.department = value;
      if (value) {
        const depart = this.getAllDepartment;
        const departName = depart.find(
          (depart) => depart.department_id == value
        );
        this.departmentTitle = departName.department_name;
      } else {
        this.departmentTitle = "";
      }
      this.dataGrid.refresh();
    },
    confirmDestroy() {
      this.$store
        .dispatch(
          "regulationList/destroyRegulationLists",
          this.deleteRegulation
        )
        .then((res) => {
          notify("delete success", "success", 2000);
          this.dataGrid.refresh();
        })
        .catch((e) => {
          notify(e.message, "error", 2000);
        });
      this.popupDelete = false;
    },
    popupDestroy(params) {
      if (params.row.data.status == "done" && this.manageRegulation) {
        notify("Data regulation done!", "warning", 2000);
        return;
      }
      this.popupDelete = true;

      this.deleteRegulation = params.row.data;
    },
    async details(params) {
      localStorage.setItem(
        "acc_regul",
        JSON.stringify({
          per: this.permissions,
          manageRegulation: this.manageRegulation,
          verifiedRegulation: this.verifiedRegulation,
        })
      );
      await this.$store.dispatch(
        "regulationList/setRegulationList",
        params.row.data
      );
      router.push(`/regulation-detail/${params.row.key}`);
    },
    async edit(params) {
      if (params.row.data.status == "done" && this.manageRegulation) {
        notify("Data regulation done!", "warning", 2000);
        return;
      }
      await this.$store.dispatch(
        "regulationList/setRegulationList",
        params.row.data
      );
      router.push(`/regulation-list/form/${params.row.key}`);
    },
    onToolbarPreparing(e) {
      const vThis = this;
      e.toolbarOptions.items.unshift({
        location: "after",
        widget: "dxButton",
        options: {
          icon: "refresh",
          stylingMode: "outlined",
          type: "default",
          onInitialized: function (e) {
            this.btnAdd = e.component;
          },
          onClick: function () {
            vThis.dataGrid.refresh();
          },
        },
      });
      if (this.permissions.canCreate) {
        e.toolbarOptions.items.unshift({
          location: "before",
          widget: "dxButton",
          options: {
            icon: "add",
            text: "Tambah",
            stylingMode: "outlined",
            type: "default",
            onInitialized: function (e) {
              this.btnAdd = e.component;
            },
            onClick: function () {
              router.push("/regulation-list/form");
            },
          },
        });
      } else if (this.permissions.canVerified) {
        e.toolbarOptions.items.unshift({
          location: "after",
          widget: "dxButton",
          options: {
            icon: "export",
            text: "Reports",
            stylingMode: "outlined",
            type: "default",
            onInitialized: function (e) {
              this.btnAdd = e.component;
            },
            onClick: function () {
              vThis.onExporting();
            },
          },
        });
      }
    },
    async fetch(params = {}) {
      // eslint-disable-next-line no-console
      if (Object.keys(params).length === 0) {
        params = {};
      }

      if (this.permissions.unfilteredData == false) {
        if (this.manageRegulation) {
          params.department = this.getUserDetail.department_id;
          // params.status = this.valueStatus;
          this.title = `Kelola Regulasi Pemerintah ${this.getUserDetail.department_name}`;
        }
      } else {
        if (this.permissions.canVerified) {
          this.title = `Verifikasi Regulasi ${this.departmentTitle}`;
          params.verification = true;
        } else {
          // params.status = this.valueStatus;
          this.title = `Semua Daftar Regulasi Pemerintah ${this.departmentTitle}`;
        }
      }

      let text = "?";
      const keys = Object.keys(params);
      keys.forEach((i) => {
        if (i in params && isNotEmpty(params[i])) {
          text += `${i}=${JSON.stringify(params[i])}&`;
        }
      });
      text = text.slice(0, -1);

      var results = await this.$store.dispatch(
        "regulationList/getRegulationLists",
        text.replace(/['"]+/g, "")
      );
      this.export = await results;
      console.log("RESULTS : ", results);
      // eslint-disable-next-line no-console
      var datax = {
        data: results.data,
        totalCount: results.paginate.total || 0,
      };
      return datax;
    },
  },
};
</script>
<style scoped>
.done {
  background-color: green;
  color: white;
  font-weight: bold;
  text-align: center;
  border-radius: 25px;
  padding: 2%;
}
.process {
  background-color: yellow;
  color: black;
  font-weight: bold;
  text-align: center;
  border-radius: 25px;
  padding: 2%;
}

.popup-property-details {
  overflow: hidden;
  position: relative;
}
.justify-center {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.customBtn {
  margin: 1%;
}
</style>