<template>
  <div>
    <h2 class="content-block">
      Daftar <span v-if="history">Riwayat</span> Catatan Hasil Verifikasi
      {{ title }}
    </h2>
    <filter-table-unit-bisnis
      @get-unit-bisnis="getCompany"
      @get-department="getDepartment"
      v-if="permissions.unfilteredData"
    ></filter-table-unit-bisnis>

    <br />
    <br />
    <dx-data-grid
      class="dx-card wide-card"
      :data-source="dataSource"
      :show-borders="false"
      :column-auto-width="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :remote-operations="true"
      :word-wrap-enabled="true"
      :ref="gridRef"
      @toolbar-preparing="onToolbarPreparing($event)"
    >
      <dx-paging :page-size="10" />
      <dx-pager
        :show-page-size-selector="true"
        :show-info="true"
        :allowed-page-sizes="pageSizes"
        :showNavigationButtons="true"
        infoText="Hal {0} dari {1} ({2} items)"
        :visible="true"
      />
      <DxSearchPanel :visible="true" />

      <dx-column type="buttons" :width="110" data-field="Action">
        <DxButton
          hint="edit"
          icon="fa fa-pen-to-square"
          @click="edit"
          v-if="!history && permissions.canUpdate"
        />
        <DxButton hint="detail" icon="fa fa-circle-info" @click="onDetail" />
        <DxButton
          hint="detail"
          icon="fa fa-check-to-slot"
          @click="onVerification"
          v-if="permissions.canVerified"
        />
        <!-- v-if="!history" -->
        <DxButton
          hint="hapus"
          icon="fa fa-trash"
          @click="popupDestroy"
          v-if="permissions.canDelete"
        />
      </dx-column>
      <dx-column
        data-field="audit_checklist.department_id"
        caption="Department"
        :customize-text="departmentName"
        :allow-sorting="false"
      />
      <dx-column
        data-field="verification_desc"
        caption="Catatan Hasil Verifikasi"
      />
      <dx-column
        data-field="verification_status"
        caption="Status Verifikasi"
        cell-template="grid-cell"
        width="100"
      />
      <template #grid-cell="{ data }">
        <div
          :class="{
            ok: data.value == 'OK',
            nc: data.value == 'NC',
            obs: data.value == 'Obs',
          }"
        >
          {{ data.value }}
        </div>
      </template>
      <dx-column
        data-field="status"
        caption="Status Temuan"
        cell-template="grid-cell-status"
        width="100"
      />
      <template #grid-cell-status="{ data }">
        <div
          :class="{
            done: data.value,
            submit: !data.value,
          }"
        >
          {{ data.value ? "Done" : "Submit" }}
        </div>
      </template>
      <dx-column data-field="auditee" caption="Auditee" />
      <dx-column data-field="auditor" caption="Auditor" />
    </dx-data-grid>

    <!-- popup detail -->
    <DxPopup
      width="50%"
      height="auto"
      :show-title="true"
      title="Detail Checklist"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      :show-close-button="true"
      v-model:visible="popupDetail"
    >
      <template #content="{}">
        <div class="popup-property-details">
          <div class="dx-fieldset">
            <div class="dx-field">
              <div class="dx-field-label">Department</div>
              <div class="dx-field-value">
                : {{ detailData.department_name }}
              </div>
            </div>
            <div class="dx-field">
              <div class="dx-field-label">Clausul Relevan</div>
              <div class="dx-field-value">
                : {{ detailData.clausul_relevan }}
              </div>
            </div>
            <div class="dx-field">
              <div class="dx-field-label">Prosess</div>
              <div class="dx-field-value">: {{ detailData.process_name }}</div>
            </div>
            <div class="dx-field">
              <div class="dx-field-label">Lingkup Aktivitas</div>
              <div class="dx-field-value">: {{ detailData.activity }}</div>
            </div>
            <div class="dx-field">
              <div class="dx-field-label">PIC</div>
              <div class="dx-field-value">: {{ detailData.pic }}</div>
            </div>
            <div class="dx-field">
              <div class="dx-field-label">Potensi Risiko</div>
              <div class="dx-field-value">: {{ detailData.potential }}</div>
            </div>
            <div class="dx-field">
              <div class="dx-field-label">Tingkat Risiko</div>
              <div class="dx-field-value">: {{ detailData.risk_level }}</div>
            </div>
            <div class="dx-field">
              <div class="dx-field-label">Pengendalian Risiko</div>
              <div class="dx-field-value">
                : {{ detailData.risk_control_name }}
              </div>
            </div>
            <div class="dx-field">
              <div class="dx-field-label">Tipe Pengendalian Risiko</div>
              <div class="dx-field-value">: {{ detailData.type }}</div>
            </div>
            <div class="dx-field">
              <div class="dx-field-label">Verifikasi Item</div>
              <div class="dx-field-value">
                : {{ detailData.verification_items }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </DxPopup>

    <!-- popup export -->
    <DxPopup
      width="50%"
      height="auto"
      :show-title="true"
      title="Konfirmasi Export Checklist Hasil Verifikasi"
      :drag-enabled="false"
      :show-close-button="true"
      v-model:visible="popupExport"
    >
      <template #content="{}">
        <div class="popup-property-details">
          <div>
            <form action="/editVerificationReport" @submit.prevent="onExport()">
              <div>Pilih tanggal verifikasi</div>
              <DxDateRangeBox
                class="custom-form"
                :multi-view="false"
                :show-clear-button="true"
                display-format="d / MM / yyyy"
                v-model:value="currentValueExport"
                ><DxValidator>
                  <DxRequiredRule message="Required" /> </DxValidator
              ></DxDateRangeBox>

              <div class="justify-center">
                <DxButtonx
                  v-if="!loading"
                  class="customBtn"
                  text="Export"
                  icon="export"
                  type="default"
                  :use-submit-behavior="true"
                />
                <DxLoadIndicator v-else class="custom-form" />
              </div>
            </form>
          </div>
        </div>
      </template>
    </DxPopup>

    <!-- popup Verif -->
    <DxPopup
      :width="300"
      height="auto"
      :show-title="true"
      title="Konfirmasi"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      v-model:visible="popupVerif"
    >
      <template #content="{}">
        <div class="popup-property-details">
          <b>Pastikan catatan hasil verifikasi sudah dicek</b>
          <div class="justify-center">
            <DxButtonx
              class="customBtn"
              text="ya"
              icon="check"
              type="success"
              @click="confirmVerif()"
            />
            <DxButtonx
              class="customBtn"
              text="batal"
              icon="close"
              type="danger"
              @click="popupVerif = false"
            />
          </div>
        </div>
      </template>
    </DxPopup>

    <!-- popup deleted -->
    <DxPopup
      :width="300"
      height="auto"
      :show-title="true"
      title="Konfirmasi"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      v-model:visible="popupDelete"
    >
      <template #content="{}">
        <div class="popup-property-details">
          <b>Apakah yakin hapus data ?</b>
          <div class="justify-center">
            <DxButtonx
              class="customBtn"
              text="ya"
              icon="check"
              type="success"
              @click="confirmDestroy()"
            />
            <DxButtonx
              class="customBtn"
              text="batal"
              icon="close"
              type="danger"
              @click="popupDelete = false"
            />
          </div>
        </div>
      </template>
    </DxPopup>
  </div>
</template>
            <script>
import DxDataGrid, {
  DxColumn,
  DxFilterRow,
  DxLookup,
  DxPager,
  DxPaging,
  DxEditing,
  DxForm,
  DxSearchPanel,
  DxButton,
  DxMasterDetail,
} from "devextreme-vue/data-grid";
import DxPopup from "devextreme-vue/popup";
import DxButtonx from "devextreme-vue/button";
import FilterTableUnitBisnis from "@/components/partials/FilterTableUnitBisnis.vue";
import DxDateRangeBox from "devextreme-vue/date-range-box";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";

import CustomStore from "devextreme/data/custom_store";
import axios from "axios";
import router from "@/router";
import { mapGetters, mapActions } from "vuex";
import notify from "devextreme/ui/notify";

const isNotEmpty = (value) =>
  value !== undefined && value !== null && value !== "";
export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxLookup,
    DxPager,
    DxPaging,
    DxEditing,
    DxPopup,
    DxForm,
    DxSearchPanel,
    DxButton,
    DxMasterDetail,
    DxButtonx,
    DxDateRangeBox,
    FilterTableUnitBisnis,
    DxLoadIndicator,
    DxValidator,
    DxRequiredRule,
  },
  data() {
    const vthis = this;
    const dataSource = {
      store: new CustomStore({
        key: "id",
        load: async function (loadOptions) {
          var sortedBy = "";
          var sort = "";
          let filterValue = "";
          if (loadOptions.sort) {
            sortedBy += loadOptions.sort[0].selector;
            if (loadOptions.sort[0].desc) {
              sort += "desc";
            } else {
              sort += "asc";
            }
          }

          if (loadOptions.filter) {
            filterValue = loadOptions.filter[0].filterValue;
          }

          var perPage = loadOptions.take;
          var offset = loadOptions.skip;
          var page = offset / perPage + 1;

          var datax = vthis.fetch({
            limit: perPage || 10,
            page: page || 1,
            sorted_by: sortedBy || "updated_at",
            sort: sort || "desc",
            search: filterValue,
            company: vthis.company || "",
            department: vthis.department || "",
          });

          return await datax;
        },
      }),
    };
    return {
      gridRef: "pic-checklist-table",
      dataSource: dataSource,
      pageSizes: [5, 10, 15],
      company: "",
      department: "",
      popupDelete: false,
      deleteData: {},
      popupDetail: false,
      detailData: {},
      title: "",
      popupVerif: false,
      dataVerif: {},
      permissions: {
        canCreate: null,
        canUpdate: null,
        canDelete: null,
        canVerified: null,
        unfilteredData: null,
      },
      currentValueExport: [],
      popupExport: false,
      loading: false,
    };
  },
  created() {
    const permission = this.$route.meta.permission;
    // check permission
    this.permissions.canCreate = (permission & 2) > 0;
    this.permissions.canUpdate = (permission & 4) > 0;
    this.permissions.canVerified = (permission & 8) > 0;
    this.permissions.canDelete = (permission & 16) > 0;
    this.permissions.unfilteredData = (permission & 32) > 0;
  },
  mounted() {},
  props: {
    roles: {
      type: String,
    },
    auditRiskControl: {
      type: Object,
    },
    auditChecklist: {
      type: Object,
    },
    history: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dataGrid: function () {
      return this.$refs[this.gridRef].instance;
    },
    ...mapGetters("department", ["getAllDepartment"]),
  },
  methods: {
    formatDate(params) {
      if (!params) return "";
      const day = String(params.getDate());
      const month = String(params.getMonth() + 1);
      const year = params.getFullYear();
      return `${year}-${month}-${day}`;
    },
    async onExport() {
      this.loading = true;

      // get audit findings
      const start = this.formatDate(this.currentValueExport[0]);
      const end = this.formatDate(this.currentValueExport[1]);
      const text = `?limit=999999&start_date=${start},${end} 23:59:59`;
      const getAuditFindings = await this.$store.dispatch(
        "auditFinding/getAuditFinding",
        text
      );
      const data = getAuditFindings.data;
      console.log("EXPORT", data);

      if (data.length < 1) {
        notify("data tidak ditemukan", "warning", 2000);
        this.loading = false;
        return;
      } else {
        // Membuat workbook baru
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet("Data RFD");

        // Definisikan kolom secara dinamis
        const columns = [
          {
            header: "Department",
            key: "audit_checklist",
            transformer: (checklist) =>
              this.departmentName({ value: checklist.department_id }),
          },
          { header: "Catatan Hasil Verifikasi", key: "verification_desc" },
          { header: "Status", key: "verification_status" },
          { header: "Auditee", key: "auditee" },
          { header: "Auditor", key: "auditor" },
        ];

        // Tambahkan header
        worksheet.columns = columns.map((col) => ({
          header: col.header,
          key: col.key,
          width: 20, // Lebar kolom default
        }));

        // Terapkan gaya untuk header
        const headerRow = worksheet.getRow(1); // Baris pertama sebagai header
        headerRow.eachCell((cell) => {
          cell.font = {
            bold: true, // Ubah font menjadi bold
          };
          cell.alignment = {
            horizontal: "center", // Teks rata tengah secara horizontal
            vertical: "middle", // Teks rata tengah secara vertikal
          };
          cell.border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        });

        data.forEach((item) => {
          // Tambahkan data utama di baris pertama
          const mainRowData = {};
          columns.forEach(({ key, transformer }) => {
            mainRowData[key] = transformer ? transformer(item[key]) : item[key];
          });

          const mainRow = worksheet.addRow(mainRowData);
          // Menambahkan border untuk setiap cell di baris yang baru dimasukkan
          mainRow.eachCell((cell) => {
            cell.border = {
              top: { style: "thin" },
              left: { style: "thin" },
              bottom: { style: "thin" },
              right: { style: "thin" },
            };
          });
        });

        // Menambahkan border untuk seluruh range sel (dari baris pertama hingga terakhir)
        // Dapatkan jumlah baris dan kolom yang ada
        const rowCount = worksheet.rowCount;
        const columnCount = worksheet.columns.length;

        // Menambahkan border untuk seluruh worksheet
        for (let rowIndex = 1; rowIndex <= rowCount; rowIndex++) {
          const row = worksheet.getRow(rowIndex);
          for (let colIndex = 1; colIndex <= columnCount; colIndex++) {
            const cell = row.getCell(colIndex);
            if (cell) {
              cell.border = {
                top: { style: "thin" },
                left: { style: "thin" },
                bottom: { style: "thin" },
                right: { style: "thin" },
              };
            }
          }
        }

        // export file excle
        workbook.xlsx
          .writeBuffer()
          .then((buffer) => {
            saveAs(
              new Blob([buffer], { type: "application/octet-stream" }),
              `Laporan Catatan Hasil Verifikasi (${start})(${end}).xlsx`
            );
            // close popup
            this.popupExport = false;
            this.loading = false;
          })
          .catch((e) => {
            this.loading = false;
            console.log(e, "error");
            notify(e.message, "error", 2000);
          });
      }
    },
    confirmVerif() {
      this.verifData.status = !this.verifData.status;
      this.$store
        .dispatch("auditFinding/editAuditFinding", this.verifData)
        .then((res) => {
          notify("verifikasi success", "success", 2000);
          this.dataGrid.refresh();
        })
        .catch((e) => {
          notify(e.message, "error", 2000);
        });
      this.popupVerif = false;
    },
    onVerification(params) {
      this.popupVerif = true;
      this.verifData = params.row.data;
    },
    getCompany(value) {
      this.company = value;
      this.dataGrid.refresh();
    },
    getDepartment(value) {
      this.department = value;
      if (value) {
        const depart = this.getAllDepartment;
        const departName = depart.find(
          (depart) => depart.department_id == value
        );
        this.title = departName.department_name;
      } else {
        this.title = "";
      }
      this.dataGrid.refresh();
    },
    departmentName(cellInfo) {
      const depart = this.getAllDepartment.find(
        (res) => res.department_id == cellInfo.value
      );

      if (!depart) {
        return "-";
      }
      return `${depart.company_name} | ${depart.department_name}`;
    },
    confirmDestroy() {
      this.$store
        .dispatch("auditFinding/destroyAuditFinding", this.deleteData)
        .then((res) => {
          notify("delete success", "success", 2000);
          this.dataGrid.refresh();
        })
        .catch((e) => {
          notify(e.message, "error", 2000);
        });
      this.popupDelete = false;
    },
    onDetail(params) {
      const text = `/checklist/${params.row.data.verification_item_id}`;
      this.$store
        .dispatch("auditVerificationItem/getAuditVerificationItem", text)
        .then((res) => {
          this.popupDetail = true;
          this.detailData = res.data;
          this.detailData.department_name = this.departmentName({
            value: params.row.data.audit_checklist.department_id,
          });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    popupDestroy(params) {
      this.popupDelete = true;

      this.deleteData = params.row.data;
    },
    async edit(params) {
      this.$store.dispatch("auditFinding/setAuditFinding", params.row.data);
      router.push(`/audit-finding/form/${params.row.key}`);
    },
    onToolbarPreparing(e) {
      const vThis = this;
      if (!this.history && this.permissions.canCreate) {
        e.toolbarOptions.items.unshift({
          location: "before",
          widget: "dxButton",
          options: {
            icon: "add",
            text: "Tambah",
            stylingMode: "outlined",
            type: "default",
            onInitialized: function (e) {
              this.btnAdd = e.component;
            },
            onClick: function () {
              // vThis.$store.dispatch("auditFinding/setAuditFindingStatus", {
              //   action: "Add",
              //   roles: vThis.roles,
              // });
              router.push(`/audit-finding/form`);
            },
          },
        });
      }
      if (this.permissions.unfilteredData && this.history) {
        e.toolbarOptions.items.unshift({
          location: "after",
          widget: "dxButton",
          options: {
            icon: "export",
            stylingMode: "outlined",
            type: "default",
            onInitialized: function (e) {
              this.btnAdd = e.component;
            },
            onClick: function () {
              vThis.popupExport = true;
            },
          },
        });
      }
      e.toolbarOptions.items.unshift({
        location: "after",
        widget: "dxButton",
        options: {
          icon: "refresh",
          stylingMode: "outlined",
          type: "default",
          onInitialized: function (e) {
            this.btnAdd = e.component;
          },
          onClick: function () {
            vThis.dataGrid.refresh();
          },
        },
      });
    },
    async fetch(params = {}) {
      // eslint-disable-next-line no-console
      if (Object.keys(params).length === 0) {
        params = {};
      }

      if (this.history) {
        params.status = true;
      } else {
        params.status = false;
      }

      let text = "?";
      const keys = Object.keys(params);
      keys.forEach((i) => {
        if (i in params && isNotEmpty(params[i])) {
          text += `${i}=${JSON.stringify(params[i])}&`;
        }
      });
      text = text.slice(0, -1);

      var results = await this.$store.dispatch(
        "auditFinding/getAuditFinding",
        text.replace(/['"]+/g, "")
      );
      // eslint-disable-next-line no-console
      var datax = await {
        data: results.data,
        totalCount: results.paginate.total,
      };

      return await datax;
    },
  },
};
</script>
            <style scoped>
.popup-property-details {
  overflow: hidden;
  position: relative;
}
.justify-center {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.customBtn {
  margin: 1%;
}
.master-detail-caption {
  padding: 0 0 5px 10px;
  font-size: 14px;
  font-weight: bold;
}
.submit {
  background-color: #03a9f4;
  color: white;
  font-weight: bold;
  text-align: center;
  border-radius: 25px;
  padding: 2%;
}
.done {
  background-color: #8bc34a;
  color: white;
  font-weight: bold;
  text-align: center;
  border-radius: 25px;
  padding: 2%;
}
.ok {
  background-color: #8db4e2;
  color: white;
  font-weight: bold;
  text-align: center;
  border-radius: 25px;
  padding: 2%;
}
.nc {
  background-color: red;
  color: white;
  font-weight: bold;
  text-align: center;
  border-radius: 25px;
  padding: 2%;
}
.obs {
  background-color: yellow;
  color: black;
  font-weight: bold;
  text-align: center;
  border-radius: 25px;
  padding: 2%;
}
</style>