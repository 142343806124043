import axios from 'axios';
import Cookie from 'js-cookie'
import Api from '@/services/Api';
import CryptoJS from 'crypto-js';

export default {
    namespaced: true,
    state() {
        return {
            paletteCollection: ['Material', 'Soft Pastel', 'Harmony Light', 'Pastel', 'Bright', 'Soft', 'Ocean', 'Office', 'Vintage', 'Violet', 'Carmine', 'Dark Moon', 'Soft Blue', 'Dark Violet', 'Green Mist']
        }
    },
    mutations: {
    },
    actions: {
        async getChartAuditFinding({ commit, state }, params) {
            return await Api().get(
                `charts/audit-finding${params}`,
            ).then((res) => {
                return res.data
            })
        },
        async getChartImprovementTheme({ commit, state }, params) {
            return await Api().get(
                `charts/improv-theme${params}`,
            ).then((res) => {
                return res.data
            })
        },
        async getChartImprovementType({ commit, state }, params) {
            return await Api().get(
                `charts/improv-type${params}`,
            ).then((res) => {
                return res.data
            })
        },
        async getChartDocumentExpired({ commit, state }, params) {
            return await Api().get(
                `/charts/document-expired-review${params}`,
            ).then((res) => {
                return res.data
            })
        },
    },
    getters: {
        getPalettes(state) {
            return state.paletteCollection;
        },
    }
}