import Api from '@/services/Api';

export default {
    namespaced: true,
    state() {
        return {
            improvementTheme: null,
            improvementThemeStatus: null,
        }
    },
    mutations: {
        setImprovementTheme(state, payload) {
            state.improvementTheme = payload
        },
        setImprovementThemeStatus(state, payload) {
            state.improvementThemeStatus = payload
        },
    },
    actions: {
        setImprovementTheme({ commit }, params) {
            commit('setImprovementTheme', params)
        },
        setImprovementThemeStatus({ commit }, params) {
            commit('setImprovementThemeStatus', params)
        },
        async getImprovementTheme({ commit, state }, params) {
            return await Api().get(
                `improvement-themes${params}`
            ).then((res) => {
                return res.data
            }).catch((err) => {
                console.log(err.message);
            });
        },
        async postImprovementTheme({ commit, state }, params) {
            return await Api().post(
                `improvement-themes`, params, { headers: { "Content-Type": "multipart/form-data" }, }
            ).then((res) => {
                return res.data
            })
        },
        async editImprovementTheme({ commit, state }, params) {
            return await Api().post(
                `improvement-themes/${params.id}`, params, { headers: { "Content-Type": "multipart/form-data" }, }
            ).then((res) => {
                return res.data
            })
        },
        async destroyImprovementTheme({ commit, state }, params) {
            return await Api().delete(
                `improvement-themes/${params.id}`
            ).then((res) => {
                return res.data
            })
        }
    },
    getters: {
        getImprovementTheme(state) {
            return state.improvementTheme;
        },
        getImprovementThemeStatus(state) {
            return state.improvementThemeStatus;
        },
    }
}