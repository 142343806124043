<template>
  <div>
    <h2 class="content-block">
      Proses / Aktivitas :
      {{ risk?.process_name }}
    </h2>

    <dx-data-grid
      class="dx-card wide-card"
      :data-source="dataSource"
      :show-borders="false"
      :column-auto-width="true"
      :show-row-lines="true"
      :row-alternation-enabled="true"
      :remote-operations="true"
      :word-wrap-enabled="true"
      :ref="gridRef"
      @toolbar-preparing="onToolbarPreparing($event)"
    >
      <dx-paging :page-size="10" />
      <dx-pager
        :show-page-size-selector="true"
        :show-info="true"
        :allowed-page-sizes="pageSizes"
        :showNavigationButtons="true"
        infoText="Hal {0} dari {1} ({2} items)"
        :visible="true"
      />
      <DxSearchPanel :visible="true" />

      <dx-column type="buttons" :width="110" data-field="Action">
        <DxButton
          hint="detail"
          icon="fa fa-book-open-reader"
          @click="viewDetail"
        />
        <DxButton
          hint="edit"
          icon="fa fa-pen-to-square"
          @click="edit"
          v-if="permissions.canUpdate && itemRisk.manageRisk"
        />
        <DxButton
          hint="hapus"
          icon="fa fa-trash"
          @click="popupDestroy"
          v-if="permissions.canDelete && itemRisk.manageRisk"
        />
      </dx-column>
      <DxColumn caption="IDENTIFIKASI RISIKO" alignment="center">
        <dx-column data-field="risk_failure" caption="KEGAGALAN / RISIKO" />
        <DxColumn
          caption="IDENTIFIKASI / ANALISA PENYEBAB TERJADINYA RISIKO"
          alignment="center"
        >
          <dx-column
            data-field="risk_couse_internal"
            caption="FAKTOR INTERNAL"
          />
          <dx-column
            data-field="risk_couse_external"
            caption="FAKTOR EKSTERNAL"
          />
        </DxColumn>
        <DxColumn caption="DAMPAK DARI RISIKO" alignment="center">
          <dx-column data-field="risk_impact_internal" caption="KE INTERNAL" />
          <dx-column data-field="risk_impact_external" caption="KE EKSTERNAL" />
        </DxColumn>
      </DxColumn>

      <DxMasterDetail :enabled="true" template="masterDetailTemplate" />
      <template #masterDetailTemplate="{ data: riskIdentification }">
        <masterDetailRisk
          :riskIdentification="riskIdentification.data"
          :permissionProps="permissions"
        />
        <!-- @delete-data="refreshDelete" -->
      </template>
    </dx-data-grid>

    <!-- popup detail -->
    <DxPopup
      :width="popupWidth"
      height="90%"
      :show-title="true"
      title="Detail Identifikasi Risiko"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      :show-close-button="true"
      v-model:visible="popupDetail"
    >
      <template #content="{}">
        <DxScrollView width="100%" height="100%">
          <div class="popup-property-details">
            <div class="dx-fieldset">
              <div class="dx-card responsive-paddings">
                <div class="title-detail">Detail Laporan Improvement</div>

                <div class="dx-field">
                  <div class="dx-field-label">PROSES / AKTIVITAS</div>
                  <div class="dx-field-value">: {{ risk?.process_name }}</div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">KEGAGALAN / RISIKO</div>
                  <div class="dx-field-value">
                    : {{ detailData.risk_failure }}
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">FAKTOR INTERNAL</div>
                  <div class="dx-field-value">
                    : {{ detailData.risk_couse_internal }}
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">FAKTOR EKSTERNAL</div>
                  <div class="dx-field-value">
                    : {{ detailData.risk_couse_external }}
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">KE INTERNAL</div>
                  <div class="dx-field-value">
                    : {{ detailData.risk_impact_internal }}
                  </div>
                </div>
                <div class="dx-field">
                  <div class="dx-field-label">KE EKSTERNAL</div>
                  <div class="dx-field-value">
                    : {{ detailData.risk_impact_external }}
                  </div>
                </div>
              </div>

              <div class="dx-card responsive-paddings">
                <div class="title-detail">Penilaian Matrix</div>
                <div>
                  <div
                    class="container-info"
                    v-if="detailMatrix.matrix != null"
                  >
                    <div
                      :class="{
                        LOW: detailMatrix.matrix == 'LOW',
                        MEDIUM: detailMatrix.matrix == 'MEDIUM',
                        HIGH: detailMatrix.matrix == 'HIGH',
                        EXTREME: detailMatrix.matrix == 'EXTREME',
                      }"
                    >
                      {{ detailMatrix.matrix }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="dx-card responsive-paddings">
                <div class="title-detail">Penilaian Pengendalian Risiko</div>
                <div>
                  <table class="dx-table">
                    <tr
                      class="dx-row"
                      v-for="data in detailMatrix.score_control"
                      :key="data.id"
                    >
                      <td class="dx-label">{{ data.desc_risk_control }}</td>
                      <td class="dx-value">{{ data.score_indicator }}</td>
                      <td class="dx-value">
                        ( {{ data.name_score_indicator }} )
                      </td>
                    </tr>
                    <tr>
                      <td class="dx-label"><b>Total</b></td>
                      <td class="dx-value">
                        <b>{{ detailMatrix.total_control }}</b>
                      </td>
                      <td>%</td>
                    </tr>
                  </table>
                </div>
              </div>

              <div class="dx-card responsive-paddings">
                <div class="title-detail">Detail Pengendalian Risiko</div>
                <div>
                  <table class="dx-table">
                    <tr
                      class="dx-row"
                      v-for="data in riskControlDetail"
                      :key="data.id"
                    >
                      <td class="dx-label">{{ data.control_description }}</td>
                      <td class="dx-value">: {{ data.description }}</td>
                    </tr>
                  </table>
                </div>
              </div>

              <div class="dx-card responsive-paddings">
                <div class="title-detail">Penilaian Keparahan Risiko</div>
                <div>
                  <table class="dx-table">
                    <tr
                      class="dx-row"
                      v-for="data in detailMatrix.score_severity"
                      :key="data.id"
                    >
                      <td class="dx-label">{{ data.desc_risk_severities }}</td>
                      <td class="dx-value">{{ data.score_indicator }}</td>
                      <td class="dx-value">
                        ( {{ data.name_score_indicator }} )
                      </td>
                    </tr>
                    <tr>
                      <td class="dx-label"><b>Total</b></td>
                      <td class="dx-value">
                        <b>{{ detailMatrix.total_severity }}</b>
                      </td>
                      <td>%</td>
                    </tr>
                  </table>
                </div>
              </div>

              <div class="dx-card responsive-paddings">
                <div class="title-detail">Rencana Perbaikan</div>
                <div v-for="data in riskImpPlan" :key="data.id">
                  <div class="dx-card responsive-paddings">
                    <div class="dx-field">
                      <div class="dx-field-label">{{ data.desc_item }}</div>
                      <div class="dx-field-value">: {{ data.description }}</div>
                    </div>
                    <div class="dx-field">
                      <div class="dx-field-label">By Who</div>
                      <div class="dx-field-value">: {{ data.improve_by }}</div>
                    </div>
                    <div class="dx-field">
                      <div class="dx-field-label">By When</div>
                      <div class="dx-field-value">
                        : {{ dateColumn(data.improve_at) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </DxScrollView>
      </template>
    </DxPopup>

    <!-- popup delete -->
    <DxPopup
      :width="300"
      height="auto"
      :show-title="true"
      title="Konfirmasi"
      :drag-enabled="false"
      :hide-on-outside-click="true"
      v-model:visible="popupDelete"
    >
      <template #content="{}">
        <div class="popup-property-details">
          <b>Apakah yakin hapus data ?</b>
          <div class="justify-center">
            <DxButtonx
              class="customBtn"
              text="ya"
              icon="check"
              type="success"
              @click="confirmDestroy()"
            />
            <DxButtonx
              class="customBtn"
              text="batal"
              icon="close"
              type="danger"
              @click="popupDelete = false"
            />
          </div>
        </div>
      </template>
    </DxPopup>
  </div>
</template>
          <script>
import DxDataGrid, {
  DxColumn,
  DxFilterRow,
  DxLookup,
  DxPager,
  DxPaging,
  DxEditing,
  DxForm,
  DxSearchPanel,
  DxButton,
  DxMasterDetail,
} from "devextreme-vue/data-grid";
import CustomStore from "devextreme/data/custom_store";
import axios from "axios";
import DxPopup from "devextreme-vue/popup";
import router from "@/router";
import { mapGetters, mapActions } from "vuex";
import DxButtonx from "devextreme-vue/button";
import { DxScrollView } from "devextreme-vue/scroll-view";

import notify from "devextreme/ui/notify";
import masterDetailRisk from "./masterDetailRisk.vue";

const isNotEmpty = (value) =>
  value !== undefined && value !== null && value !== "";
export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxLookup,
    DxPager,
    DxPaging,
    DxEditing,
    DxPopup,
    DxForm,
    DxSearchPanel,
    DxButton,
    DxMasterDetail,
    DxButtonx,
    masterDetailRisk,
    DxScrollView,
  },
  data() {
    const vthis = this;
    const dataSource = {
      store: new CustomStore({
        key: "id",
        load: async function (loadOptions) {
          var sortedBy = "";
          var sort = "";
          let filterValue = "";
          if (loadOptions.sort) {
            sortedBy += loadOptions.sort[0].selector;
            if (loadOptions.sort[0].desc) {
              sort += "desc";
            } else {
              sort += "asc";
            }
          }

          if (loadOptions.filter) {
            filterValue = loadOptions.filter[0].filterValue;
          }

          var perPage = loadOptions.take;
          var offset = loadOptions.skip;
          var page = offset / perPage + 1;

          var datax = vthis.fetch({
            limit: perPage || 10,
            page: page || 1,
            sorted_by: sortedBy || "updated_at",
            sort: sort || "desc",
            search: filterValue,
            risk: vthis.$route.params.id,
          });

          return await datax;
        },
      }),
    };
    return {
      gridRef: "risk-identification-table",
      dataSource: dataSource,
      pageSizes: [5, 10, 15],
      btnAdd: null,
      popupAdd: false,
      allDepartments: [],
      popupDelete: false,
      deleteRiskIdentification: {},
      risk: {},
      itemRisk: {},
      permissions: {
        canCreate: null,
        canUpdate: null,
        canDelete: null,
        canVerified: null,
        unfilteredData: null,
      },
      popupDetail: false,
      detailData: {},
      detailMatrix: {},
      riskControlDetail: {},
      riskImpPlan: {},
      popupWidth: "80%",
    };
  },
  created() {
    const permission = JSON.parse(localStorage.getItem("acc_risk"));
    this.itemRisk = permission;
    // check permission
    this.getPermission(permission.per);
  },
  mounted() {
    if (this.getRisk) {
      this.risk = this.getRisk;
    } else {
      this.loadRisk(this.$route.params.id);
    }
    // start responsive popup detail
    window.addEventListener("resize", this.updatePopupSize);
    this.updatePopupSize();
    // end
  },
  beforeDestroy() {
    // start responsive popup detail
    window.removeEventListener("resize", this.updatePopupSize);
    // end
  },
  computed: {
    dataGrid: function () {
      return this.$refs[this.gridRef].instance;
    },
    ...mapGetters("risk", ["getRisk"]),
  },
  methods: {
    dateColumn(cellInfo) {
      if (!cellInfo) {
        return "-";
      }
      const date = new Date(cellInfo);
      const dd = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      const mounth = parseInt(date.getMonth()) + 1;
      const mm = mounth < 10 ? "0" + mounth : mounth;

      return dd + "/" + mm + "/" + date.getFullYear();
    },
    updatePopupSize() {
      this.popupWidth = window.innerWidth <= 600 ? "95%" : "80%";
    },
    async getRelationIdentification(params) {
      // get score matrix
      const text = `/getmatrix/${params}`;
      const getMatrix = await this.$store.dispatch(
        "riskScore/getRiskScore",
        text
      );

      this.detailMatrix = getMatrix.data;

      // get detail risk control
      const text1 = `/control-item?identification=${params}`;
      const controlDetail = await this.$store.dispatch(
        "riskControlDetail/getRiskControlDetail",
        text1
      );
      this.riskControlDetail = controlDetail.data;

      // get risk improvement plan
      const text2 = `/control-item?identification=${params}`;
      const riskImp = await this.$store.dispatch(
        "riskImprovementPlan/getRiskImpPlan",
        text2
      );
      this.riskImpPlan = riskImp.data;
    },
    viewDetail(params) {
      this.popupDetail = true;
      this.detailData = params.row.data;

      this.getRelationIdentification(params.row.key);
    },
    async loadRisk(params) {
      // reload
      try {
        const risk = await this.$store.dispatch("risk/getRisk", `/${params}`);
        this.risk = risk.data;
      } catch (error) {
        notify(error, "error", 2000);
      }
    },
    getPermission(params) {
      this.permissions.canCreate = (params & 2) > 0;
      this.permissions.canUpdate = (params & 4) > 0;
      this.permissions.canVerified = (params & 8) > 0;
      this.permissions.canDelete = (params & 16) > 0;
      this.permissions.unfilteredData = (params & 32) > 0;
    },
    confirmDestroy() {
      this.$store
        .dispatch(
          "riskIdentification/destroyRiskIdentification",
          this.deleteRiskIdentification
        )
        .then((res) => {
          notify("delete success", "success", 2000);
          this.dataGrid.refresh();
        })
        .catch((e) => {
          notify(e.message, "error", 2000);
        });
      this.popupDelete = false;
    },
    popupDestroy(params) {
      this.popupDelete = true;
      this.deleteRiskIdentification = params.row.data;
    },
    edit(params) {
      this.$store.dispatch(
        "riskIdentification/setRiskIdentification",
        params.row.data
      );
      router.push(`/risk-identification/form/edit/${params.row.key}`);
    },
    onToolbarPreparing(e) {
      const vThis = this;
      e.toolbarOptions.items.unshift({
        location: "after",
        widget: "dxButton",
        options: {
          icon: "refresh",
          stylingMode: "outlined",
          type: "default",
          onInitialized: function (e) {
            this.btnAdd = e.component;
          },
          onClick: function () {
            vThis.dataGrid.refresh();
          },
        },
      });
      if (this.permissions.canCreate && this.itemRisk.manageRisk) {
        e.toolbarOptions.items.unshift({
          location: "before",
          widget: "dxButton",
          options: {
            icon: "add",
            text: "Tambah",
            stylingMode: "outlined",
            type: "default",
            onInitialized: function (e) {
              this.btnAdd = e.component;
            },
            onClick: function () {
              router.push(`/risk-identification/form/add/${vThis.risk.id}`);
            },
          },
        });
      }
      e.toolbarOptions.items.unshift({
        location: "before",
        widget: "dxButton",
        options: {
          icon: "back",
          stylingMode: "contained",
          type: "default",
          onInitialized: function (e) {
            this.btnAdd = e.component;
          },
          onClick: function () {
            vThis.$router.back();
          },
        },
      });
    },
    async fetch(params = {}) {
      // eslint-disable-next-line no-console
      if (Object.keys(params).length === 0) {
        params = {};
      }

      let text = "?";
      const keys = Object.keys(params);
      keys.forEach((i) => {
        if (i in params && isNotEmpty(params[i])) {
          text += `${i}=${JSON.stringify(params[i])}&`;
        }
      });
      text = text.slice(0, -1);

      var results = await this.$store.dispatch(
        "riskIdentification/getRiskIdentification",
        text.replace(/['"]+/g, "")
      );
      // eslint-disable-next-line no-console
      var datax = await {
        data: results.data,
        totalCount: results.paginate.total,
      };
      return await datax;
    },
  },
};
</script>
          <style scoped>
.popup-property-details {
  overflow: hidden;
  position: relative;
}
.justify-center {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.customBtn {
  margin: 1%;
}
.title-detail {
  text-align: start;
  font-weight: bold;
  margin-bottom: 10px;
}
/* Style untuk kolom header */
.dx-list-label {
  text-align: left;
  padding: 12px;
  background-color: #f4f4f4;
  font-weight: bold;
  border: 1px solid #ddd;
}

/* Style untuk kolom nilai */
.dx-list-value {
  padding: 12px;
  border: 1px solid #ddd;
  text-align: left;
  word-wrap: break-word;
  white-space: normal;
}

/* Style untuk tabel secara keseluruhan */
.dx-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  table-layout: auto; /* Membiarkan kolom menyesuaikan dengan konten */
}

/* Style untuk baris tabel */
.dx-row {
  border-bottom: 1px solid #ddd;
}

/* Style untuk kolom header (label) */
.dx-label {
  max-width: 30%; /* Membatasi lebar kolom ini hingga 30% */
  width: auto; /* Menyesuaikan dengan konten */
  background-color: #cccccc;
  padding: 8px;
  text-align: left;
  white-space: normal; /* Membiarkan teks terbungkus */
  overflow: hidden; /* Menyembunyikan teks yang terlalu panjang */
  text-overflow: ellipsis; /* Memberikan tanda elipsis jika teks terlalu panjang */
}

/* Style untuk kolom nilai */
.dx-value {
  max-width: 30%; /* Membatasi lebar kolom ini hingga 30% */
  width: auto; /* Menyesuaikan dengan konten */
  padding: 8px;
  text-align: left;
  word-wrap: break-word; /* Membungkus kata panjang */
  white-space: normal; /* Membiarkan teks terbungkus */
  overflow: hidden; /* Menyembunyikan teks yang terlalu panjang */
  text-overflow: ellipsis; /* Memberikan tanda elipsis jika teks terlalu panjang */
}

/* Responsif untuk perangkat kecil */
@media (max-width: 768px) {
  .dx-label,
  .dx-value {
    max-width: 100%; /* Membuat kolom memenuhi lebar penuh pada perangkat kecil */
    display: block; /* Membuat kolom menjadi baris untuk perangkat kecil */
    padding: 6px;
  }

  .dx-table {
    table-layout: fixed; /* Memastikan tabel tetap responsif */
  }
}

.container-info {
  display: flex;
  justify-content: center; /* Memusatkan elemen secara horizontal */
  align-items: center; /* Memusatkan elemen secara vertikal */
}
.EXTREME {
  display: inline-block; /* Menyesuaikan lebar dengan konten */
  padding: 5px 10px; /* Memberikan padding agar teks tidak terlalu rapat */
  text-align: center;
  color: white;
  font-size: 30px;
  background-color: red;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}
.HIGH {
  display: inline-block;
  padding: 5px 10px;
  text-align: center;
  font-size: 30px;
  background-color: rgb(255, 187, 0);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}
.MEDIUM {
  display: inline-block;
  padding: 5px 10px;
  text-align: center;
  font-size: 30px;
  background-color: yellow;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}
.LOW {
  display: inline-block;
  padding: 5px 10px;
  text-align: center;
  font-size: 30px;
  background-color: rgb(121, 255, 109);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}
</style>