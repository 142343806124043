<template>
  <div class="content-block dx-card responsive-paddings">
    <form action="post-action" @submit.prevent="handleSubmit" :ref="formRef">
      <div>
        <div class="label-text">File Regulasi</div>
        <div class="fileuploader-container custom-form">
          <DxFileUploader
            select-button-text="Pilih Dokumen"
            label-text="upload file regulasi *"
            :allowed-file-extensions="fileAllowed"
            :max-file-size="6000000"
            upload-mode="useForm"
            @value-changed="getFile"
            :validationError="validationError"
          >
          </DxFileUploader>
        </div>
        <div v-if="validationError" style="color: red">File is required.</div>
        <div v-if="paramsRoute.condition == 'edit' && !file">
          File Lama : {{ documentFile.regulation_file }}
        </div>
        <div style="text-align: right; margin-bottom: 10px">
          <span>{{ fileAllowed }}</span> - <span>Max : 5 MB</span>.
        </div>
      </div>

      <DxButton
        v-if="!loading"
        text="Simpan Data"
        type="success"
        icon="save"
        :use-submit-behavior="true"
      />
      <DxLoadIndicator v-else />
    </form>
  </div>
</template>
  <script>
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import DxTextArea from "devextreme-vue/text-area";
import { DxFileUploader } from "devextreme-vue/file-uploader";
import DxDateBox from "devextreme-vue/date-box";
import { DxTextBox } from "devextreme-vue/text-box";
import { mapGetters, mapActions } from "vuex";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";
import notify from "devextreme/ui/notify";

import axios from "axios";

export default {
  components: {
    DxButton,
    DxValidator,
    DxRequiredRule,
    DxTextArea,
    DxFileUploader,
    DxDateBox,
    DxTextBox,
    DxLoadIndicator,
  },
  data() {
    return {
      documentFile: {},
      formRef: "form-document-file",
      loading: false,
      validationStatus: "valid",
      validationErrors: [],
      isValid: "",
      file: "",
      fileAllowed: [".docx", ".doc"],
      validationError: false,
      paramsRoute: this.$route.params,
    };
  },
  emits: ["get-regulation-doc"],
  props: {
    edit: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (
      this.paramsRoute.condition == "edit" &&
      this.paramsRoute.id.trim() !== ""
    ) {
      if (this.getRegulationDoc) {
        this.documentFile = this.getRegulationDoc;
      } else {
        this.loadRegDoc(this.paramsRoute.id);
      }
    }
  },
  computed: {
    ...mapGetters("regulationList", ["getRegulationList"]),
    ...mapGetters("regulationDoc", [
      "getRegulationDocStatus",
      "getRegulationDoc",
    ]),
  },
  methods: {
    async loadRegDoc(params) {
      try {
        const getRegDoc = await this.$store.dispatch(
          "regulationDoc/getRegulationDocuments",
          `/${params}`
        );
        this.documentFile = getRegDoc.data;
      } catch (error) {
        notify(error, "error", 2000);
      }
    },
    getFile(params) {
      if (params.component._files.length > 0) {
        this.loading = !params.component._files[0].isValidFileExtension;
        this.file = params.value[0];

        this.validationError = false;
      }
    },
    async handleSubmit() {
      if (this.paramsRoute.condition == "add") {
        if (!this.file) {
          this.validationError = true; // Set validation error if no files
          return;
        }
      }
      this.documentFile.regulation_file = this.file;
      this.loading = true;
      this.$emit("get-regulation-doc", this.documentFile);
    },
  },
};
</script>
  
  <style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
.fileuploader-container {
  border: 1px solid #d3d3d3;
  margin: 0;
}
.main-block {
  max-width: 900px;
  min-width: 700px;
}
.label-text {
  color: darkgray;
  display: block;
}
</style>