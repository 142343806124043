<template>
  <div>
    <div class="content-block dx-card responsive-paddings">
      <form action="/addRiskSeverity" @submit.prevent="handleSubmit">
        <div v-for="(form, index) in severity" :key="index">
          <DxTextArea
            class="custom-form"
            label="Deskripsi"
            :height="90"
            :input-attr="{ 'aria-label': 'Notes' }"
            v-model:value="form.description"
          >
            <DxValidator>
              <DxRequiredRule message="Required" />
            </DxValidator>
          </DxTextArea>
          <DxButton
            v-if="severity.length > 1"
            class="customBtn"
            type="danger"
            icon="fa fa-minus"
            @click="removeForm(index)"
          />
        </div>

        <div v-if="!loading">
          <DxButton
            v-if="paramsRoute.condition == 'add' && severity.length < 10"
            class="customBtn"
            type="default"
            icon="fa fa-plus"
            @click="addForm"
          />

          <DxButton
            text="Simpan Data"
            type="success"
            icon="save"
            :use-submit-behavior="true"
          />
        </div>
        <DxLoadIndicator v-else />
      </form>
    </div>
  </div>
</template>
            
      <script>
import {
  DxForm,
  DxSimpleItem,
  DxGroupItem,
  DxEmailRule,
} from "devextreme-vue/form";
import DxButton from "devextreme-vue/button";
import DxValidator, { DxRequiredRule } from "devextreme-vue/validator";
import { DxSelectBox } from "devextreme-vue/select-box";
import { DxTextBox } from "devextreme-vue/text-box";
import DxTextArea from "devextreme-vue/text-area";
import DxValidationSummary from "devextreme-vue/validation-summary";
import { mapGetters, mapActions } from "vuex";
import { DxLoadIndicator } from "devextreme-vue/load-indicator";

export default {
  data() {
    return {
      loading: false,
      severity: [
        {
          description: "",
        },
      ],
      itemOptions: [],
      paramsRoute: this.$route.params,
    };
  },
  mounted() {
    if (
      this.paramsRoute.condition == "edit" &&
      this.paramsRoute.id.trim() !== ""
    ) {
      if (this.getRiskSeverity) {
        this.severity[0] = this.getRiskSeverity;
      } else {
        this.loadRiskSeverity(this.paramsRoute.id);
      }
    }
  },
  emits: ["get-risk-severity"],
  computed: {
    ...mapGetters("riskSeverity", ["getRiskSeverity", "getRiskSeverityStatus"]),
  },
  methods: {
    async loadRiskSeverity(params) {
      try {
        const riskSeverity = await this.$store.dispatch(
          "riskSeverity/getRiskSeverity",
          `/${params}`
        );

        this.severity[0] = riskSeverity.data;
      } catch (error) {
        notify(error, "error", 2000);
      }
    },
    addForm() {
      this.severity.push({
        description: "",
      });
    },
    removeForm(index) {
      this.severity.splice(index, 1);
    },
    handleSubmit(e) {
      this.loading = true;
      this.$emit("get-risk-severity", this.severity);
    },
  },
  components: {
    DxForm,
    DxSimpleItem,
    DxGroupItem,
    DxEmailRule,
    DxSelectBox,
    DxTextBox,
    DxValidator,
    DxRequiredRule,
    DxValidationSummary,
    DxButton,
    DxTextArea,
    DxLoadIndicator,
  },
};
</script>
      
      <style scoped>
.custom-form {
  margin-bottom: 25px;
  margin-top: 10px;
}
.customBtn {
  margin: 1%;
}
</style>