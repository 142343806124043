<template>
  <div class="not-found-container">
    <div class="not-found-content">
      <img
        src="../assets/maintanance.png"
        alt="Page Not Found"
        class="not-found-image"
      />
      <h1>MOHON MAAF</h1>
      <p>
        Perbaikan sistem sedang berlangsung. Aplikasi tidak dapat diakses
        sementara waktu. Terima kasih atas kesabaran Anda.
      </p>
      <dx-button
        text="Kembali"
        type="default"
        stylingMode="contained"
        class="home-button"
        @click="back"
      />
    </div>
  </div>
</template>
  
  <script>
import DxButton from "devextreme-vue/button";
import Cookie from "js-cookie";
export default {
  components: {
    DxButton,
  },
  methods: {
    back() {
      const token = Cookie.get("clientSecret");
      if (!token) {
        this.$router.push("/");
      } else {
        this.$router.back();
      }
    },
  },
};
</script>
  
  <style scoped>
.not-found-container {
  display: flex;
  justify-content: center; /* Menempatkan konten di tengah secara horizontal */
  align-items: center; /* Menempatkan konten di tengah secara vertikal */
  height: 100vh; /* Menggunakan 100% tinggi viewport */
  width: 100%; /* Menggunakan 100% lebar layar */
}

.not-found-content {
  text-align: center;
  max-width: 500px;
  width: 100%; /* Menjamin konten responsif */
  padding: 20px;
  background: white;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.not-found-image {
  width: 100%;
  max-width: 500px;
  margin-bottom: 10px;
}

h1 {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

p {
  font-size: 16px;
  color: #666;
  margin-bottom: 20px;
}

.home-button {
  margin-top: 20px;
  width: 100%;
  max-width: 200px;
}
</style>